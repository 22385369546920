<template>
  <section class="fund-investments section">
    <div class="toolbar">
      <h2 class="title">Investments</h2>
      <div class="tools">
        <div class="field">
          <label class="field-label is-small has-text-weight-semibold" for="showWarrants">
            Show Warrants:
          </label>
          <input id="showWarrants" v-model="showWarrants" type="checkbox" />
        </div>

        <div class="field">
          <label class="field-label is-small has-text-weight-semibold">Group By:</label>
          <div class="select is-small">
            <select v-model="selectedGroup" selected="none">
              <option v-for="option in groupOptions" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <button class="button is-small" @click="download">Download</button>
      </div>
    </div>

    <table id="investments" class="table is-fullwidth">
      <thead>
        <tr>
          <th v-if="isGrouped" class="group">{{ $filters.capitalize(selectedGroup) }}</th>
          <th class="investment-title">Description</th>
          <th class="fixed-width">Period</th>
          <th v-if="showWarrants" class="fixed-width">Warrant</th>
          <th v-if="showWarrants" class="fixed-width">Value</th>
          <th class="fixed-width">Investment</th>
          <th class="fixed-width">Total Value</th>
        </tr>
      </thead>

      <tbody v-if="!investments || investments.length == 0">
        <tr>
          <td colspan="7" class="no-investments">No investments found</td>
        </tr>
      </tbody>

      <tbody v-for="group in groups" v-else-if="isGrouped" :key="group.id">
        <tr v-for="(investment, index) in group.investments" :key="investment.id">
          <td v-if="index == 0" :rowspan="group.investments.length" class="group">
            {{ group.name }}
            <p class="is-size-7 has-text-grey-light has-text-weight-normal">
              ${{ (group.total / 1e6).toFixed(2) }}m,
              {{ $filters.percentage((group.total / total_invested) * 100) }}
              <br />
              <span v-if="group.investments.length > 3">
                {{ group.investments.length }} investments
              </span>
            </p>
          </td>
          <td class="investment-title">
            <a target="_blank" class="has-text-dark" :href="pipedriveUrl(investment.id)">{{
              investment.title
            }}</a>
            <p class="is-size-7 has-text-grey" :class="{ 'is-italic': !description(investment) }">
              {{ description(investment) || "No description" }}
            </p>
          </td>
          <td>{{ $filters.quarter(investment.won_time) }}</td>
          <td v-if="showWarrants">{{ $filters.percentage(programEquity(investment)) }}</td>
          <td v-if="showWarrants">{{ currencyRounded(programValue(investment)) }}</td>
          <td>{{ currencyRounded(amountInvested(investment)) }}</td>
          <td>{{ currencyRounded(investment.value) }}</td>
        </tr>
        <tr></tr>
      </tbody>

      <tbody v-else>
        <tr v-for="investment in investments" :key="investment.id">
          <td class="investment-title">
            <a
              target="_blank"
              class="has-text-dark has-text-weight-semibold"
              :href="pipedriveUrl(investment.id)"
              >{{ investment.title }}</a
            >
            <p class="is-size-7 has-text-grey" :class="{ 'is-italic': !description(investment) }">
              {{ description(investment) || "No description" }}
            </p>
          </td>
          <td>{{ $filters.quarter(investment.won_time) }}</td>
          <td v-if="showWarrants">{{ $filters.percentage(programEquity(investment)) }}</td>
          <td v-if="showWarrants">{{ currencyRounded(programValue(investment)) }}</td>
          <td>{{ currencyRounded(amountInvested(investment)) }}</td>
          <td>{{ currencyRounded(investment.value) }}</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th v-if="isGrouped" class="group"></th>
          <th></th>
          <th v-if="showWarrants"></th>
          <th>Total</th>
          <th v-if="showWarrants">{{ currencyRounded(total_value - total_invested) }}</th>
          <th>{{ currencyRounded(total_invested) }}</th>
          <th>{{ currencyRounded(total_value) }}</th>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import { startOfQuarter, addMonths, format as dateFormat, isSameQuarter } from "date-fns"
import { fetchDealTypes, fetchTransactionTypes } from "@/common/deals"
import { currencyRounded } from "@/common/filters"
import pipedrive from "@/common/pipedrive"
import { downloadCSVFromTable } from "@/utils/csv"
import { toDate } from "@/utils/date"

export default {
  props: {
    investments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dealTypes: [],
      txTypes: [],
      groupOptions: [
        { value: "", text: "None" },
        { value: "company", text: "Company" },
        { value: "quarter", text: "Quarter" },
        { value: "dealType", text: "Financing Round" },
        { value: "txType", text: "Transaction Type" },
      ],
      selectedGroup: "company",
      showWarrants: false,
    }
  },
  computed: {
    total_value: function () {
      return this.investments.reduce((sum, deal) => sum + deal.value, 0)
    },
    total_invested: function () {
      return this.investments.reduce((sum, deal) => sum + this.amountInvested(deal), 0)
    },
    isGrouped() {
      return this.selectedGroup && this.groups.length > 0
    },
    companies() {
      if (this.investments == undefined || this.investments.length === 0) return []

      // Get a list of companies from each investment
      let companies = this.investments.map(function (deal) {
        const company = deal["org_id"]

        if (company) return { id: company.value, name: company.name }
        return { id: -1, name: "Unassigned" }
      })

      // Remove duplicates
      companies = companies.filter(function (value, index, self) {
        return index === self.findIndex((item) => item.id == value.id)
      })

      return companies
    },
    quarters() {
      if (this.investments == undefined || this.investments.length === 0) return []

      // Get the first and last dates of the investments
      let firstDate = new Date(this.investments[0].won_time)
      let lastDate = firstDate
      this.investments.map(function (investment) {
        const date = new Date(investment.won_time)
        lastDate = date > lastDate ? date : lastDate
        firstDate = date < firstDate ? date : firstDate
      })

      // Construct an array of quarters from first to last date
      let start = startOfQuarter(firstDate)
      const quarters = []
      while (start < lastDate) {
        quarters.push({
          name: dateFormat(start, "qqq yyyy"),
          start: start,
        })
        start = addMonths(start, 3)
      }
      return quarters
    },
    groups() {
      if (!this.investments || this.investments.length == 0) return []
      switch (this.selectedGroup) {
        case "company":
          return this.groupsByCompany()
        case "quarter":
          return this.groupsByQuarter()
        case "dealType":
          return this.groupsByDealType()
        case "txType":
          return this.groupsByTxType()
        default:
          return []
      }
    },
  },
  created() {
    this.fetchFields()
  },
  methods: {
    currencyRounded,
    fetchFields() {
      fetchDealTypes().then((types) => (this.dealTypes = types))
      fetchTransactionTypes().then((types) => (this.txTypes = types))
    },
    groupsByCompany() {
      const deals = this.investments || []
      const companies = (this.companies || []).sort((a, b) => a.name?.localeCompare(b.name))

      return companies.map((co) => {
        const investments = deals.filter((d) => co.id == d.org_id?.value) || []
        const total = this.totalInvested(investments)
        return { ...co, investments, total: total }
      })
    },
    groupsByQuarter() {
      const deals = this.investments || []
      const quarters = this.quarters.reverse()

      return quarters.map((q) => {
        const investments = deals.filter((d) => isSameQuarter(toDate(d.won_time), toDate(q.start)))
        const total = this.totalInvested(investments)
        return { ...q, investments, total }
      })
    },
    groupsByDealType() {
      const typeKey = "78935a8811a83c5fdaedf5aa4f41dcf6569e4a91"
      const deals = this.investments || []
      const typeIds = [...new Set(this.investments.map((i) => i[typeKey]))]
      const types = typeIds.map((id) => ({ id: id, name: this.dealTypes[id] }))

      return types.map((type) => {
        const investments = deals.filter((d) => d[typeKey] == type.id) || []
        const total = this.totalInvested(investments)
        return { ...type, investments, total }
      })
    },
    groupsByTxType() {
      const typeKey = "2672757793f351cb3e1a8f40795c22d3dce97f9c"
      const deals = this.investments || []
      const typeIds = [...new Set(this.investments.map((i) => i[typeKey]))]
      const txTypes = typeIds.map((id) => ({ id: id, name: this.txTypes[id] }))

      return txTypes.map((type) => {
        const investments = deals.filter((d) => d[typeKey] == type.id) || []
        const total = this.totalInvested(investments)
        return { ...type, investments, total }
      })
    },
    pipedriveUrl(id) {
      return pipedrive.web.dealUrl(id)
    },
    vehicle(investment) {
      const key = "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2"
      return investment[key] ? investment[key].name : ""
    },
    description(investment) {
      return investment["3a8519aea381ce69742a53f5010f0d3f9cb22201"]
    },
    totalInvested(investments) {
      return investments.reduce((sum, deal) => sum + this.amountInvested(deal), 0)
    },
    amountInvested(investment) {
      // If the deal has no program equity then the investment value field
      // can be used, otherwise the "investment" field needs to be used instead
      // because the value field should equal the program equity + investment
      if (!this.programEquity(investment)) return investment.value
      return investment["49f935d19c9d29dc1ab5552fad455069b32b3017"]
    },
    programEquity(investment) {
      return investment["fdef8d9342497889c83924ef6cb4b282d7dbb9d4"]
    },
    programValue(investment) {
      return investment.value - this.amountInvested(investment)
    },
    download() {
      downloadCSVFromTable("investments")
    },
  },
}
</script>

<style lang="sass" scoped>
table#investments
  margin-top: 1rem
  font-size: 0.9rem
  .fixed-width
    width: 6.5rem
  td, th
    text-align: right
    padding-top: 0.3rem
    padding-bottom: 0.3rem
    padding-left:0
    height: 3rem
    vertical-align: top
  thead th
    vertical-align: bottom
    height: unset
  td.group,
  th.group
    text-align: left
    width: 10rem
  td.group
    font-weight: 600
    vertical-align: top
  th.investment-title,
  td.investment-title
    text-align: left
  td.no-investments
    font-weight: 600
    padding-top: 0.9rem
    padding-bottom: 0.9rem
    text-align: center
    color: lightgray
</style>
