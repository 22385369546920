import { google as googleConfig } from "@/common/config"
import { netlifyFunctionClient } from "@/common/functions"
import { useGoogleStore } from "@/stores/google"
import { googleClient } from "./client"
import { setAccessToken } from "./token"
import { ensureGISLoaded, getUserEmail } from "./util"

// ** google's reference is not very good, see README in this directory. **

// scopes we will request for users when using specific google resources
const SCOPES = {
  calendar: [
    "https://www.googleapis.com/auth/calendar",
    "https://www.googleapis.com/auth/calendar.events",
  ],
  drive: ["https://www.googleapis.com/auth/drive.readonly"],
}

// when google authorizes the user it hands us an authorization code which
// we need to run through the OAuth flow to exchange for access & refresh tokens.
// we can't do this client-side so once we have the code we post it to a
// function to handle this for us.
const handleCodeRequest = function (response) {
  // https://developers.google.com/identity/oauth2/web/guides/use-code-model#popup-mode
  // https://developers.google.com/identity/oauth2/web/reference/js-reference#CodeResponse
  // console.log("auth response from google", response);
  const body = {
    code: response.code,
    scope: response.scope,
  }
  netlifyFunctionClient()
    .post("complete_authorization", body)
    .then((response) => {
      const token = response?.data?.token
      if (token) {
        setAccessToken(token)
      } else {
        console.error("No token returned from authorization", response)
      }
    })
    .catch((err) => console.error(err))
}

const handleCodeRequestError = function (err) {
  // https://developers.google.com/identity/oauth2/web/reference/js-reference#CodeResponse
  console.error("google authorization failed", err)
}

// GIS code client
let codeClient = null

const initCodeClient = async function () {
  const scopes = [...SCOPES["calendar"], ...SCOPES["drive"]]
  const email = await getUserEmail()
  const accounts = await ensureGISLoaded()
  codeClient = accounts.oauth2.initCodeClient({
    client_id: googleConfig.clientId,
    scope: scopes.join(" "),
    ux_mode: "popup",
    hint: email,
    hosted_domain: "heavybit.com",
    callback: handleCodeRequest,
    error_callback: handleCodeRequestError,
  })
}

// revoke auger's access to user's google resources & remove tokens
export const revokeGoogleAuthorization = async function () {
  await googleClient() // ensure token is current
  const googleStore = useGoogleStore()
  const token = googleStore.token?.token
  if (!token) return // no active token, need one to revoke access

  const accounts = await ensureGISLoaded()
  // send revoke request directly to google
  await accounts.oauth2.revoke(token)
  // send revoke request to backend function to clean up stored tokens
  return netlifyFunctionClient().post("revoke")
}

// open popup to start authorization process with google for the scopes we need
export const startGoogleAuthorization = async function () {
  if (!codeClient) await initCodeClient()
  return codeClient.requestCode()
}
