<template>
  <div class="funnel-element">
    <h3>{{ $filters.capitalize(units) }}</h3>
    <div class="core">
      <span class="num">{{ lastValue }}</span>
      <span v-if="trailingFourMonthAverage" class="t4" :class="trailingColor">
        <font-awesome-icon v-if="trailingPositive" icon="caret-up" />
        <font-awesome-icon v-if="trailingNegative" icon="caret-down" />
        {{ trailingAverageFormatted }}
      </span>
    </div>
    <div v-if="!final" class="funnel-down">
      <div class="conversion-rate">&darr;&nbsp;{{ conversionRateFormatted }}</div>
    </div>
  </div>
</template>

<script>
import { isPositive, isNegative } from "@/utils/number"
import { trailingAverageGrowth, percentageThreshold } from "@/utils/analysis"
import { commafy } from "@/utils/numberFormatter"

export default {
  props: {
    measurements: {
      type: Array,
      default: () => [],
    },
    units: {
      type: String,
      default: "",
    },
    conversion: {
      type: Number,
      default: null,
    },
    final: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    conversionRateFormatted() {
      if (!this.conversion) return ""
      return this.conversion.toFixed(1) + "%"
    },
    conversionTitle() {
      return "Conversion Rate: " + this.conversionRateFormatted
    },
    lastValue() {
      if (!this.measurements || this.measurements.length < 1) return "–"
      return commafy(this.measurements.slice(-1)[0].value)
    },
    trailingAverageFormatted() {
      const fourMonth = this.trailingFourMonthAverage
      if (!fourMonth) return ""
      if (!isFinite(fourMonth)) return "--%"
      return fourMonth.toFixed(1) + "%"
    },
    trailingFourMonthAverage() {
      if (this.measurements.length < 5) return null
      return trailingAverageGrowth(this.measurements.map((m) => m.value))
    },
    trailingColor() {
      return "is-" + percentageThreshold(this.trailingFourMonthAverage)
    },
    trailingPositive() {
      return isPositive(this.trailingFourMonthAverage)
    },
    trailingNegative() {
      return isNegative(this.trailingFourMonthAverage)
    },
  },
}
</script>

<style lang="sass" scoped>
.funnel-element
  border: 1px solid #ddd
  flex: 1
  display: flex
  flex-direction: column
  position: relative
  align-items: center
  justify-content: center
  margin: 0 auto 26px auto
  &:last-child
    margin-bottom: 0
  .core
    position: relative
    display: flex
    flex-direction: row
    align-items: baseline
  .num
    font-size: 1.5rem
  .t4
    font-size: 0.8rem
    margin-left: 10px
  .funnel-down
    position: absolute
    bottom: -27px
    z-index: -1
    .conversion-rate
      background: hsl(204, 86%, 90%)
      padding: 3px 3px
      color: #55b
      font-size: 0.9rem
      min-width: 2rem
    .conversion-rate:before,
    .conversion-rate:after
      content: ""
      position: absolute
      display: block
      width: 2rem
      height: 100%
      top: 0
      z-index: -1
      background: inherit
      transform-origin: bottom left
    .conversion-rate:before
      left: 0
      transform: skew(20deg, 0deg)
    .conversion-rate:after
      right: 0
      transform: skew(-20deg, 0deg)
  &:nth-child(1)
    width: 100%
  &:nth-child(2)
    width: 90%
  &:nth-child(3)
    width: 80%
  &:nth-child(4)
    width: 70%
  &:nth-child(5)
    width: 60%
  &:nth-child(6)
    width: 50%
</style>
