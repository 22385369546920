// graphql fragments

import gql from "graphql-tag"

const financingEventFields = gql`
  fragment FinancingEventFields on financing_events {
    id
    closed_on
    company_name
    company_pipedrive_id
    estimate
    financing_stage_id
    financing_stage {
      name
    }
    financing_type_id
    financing_type {
      name
    }
    investment
    lead_investor_pipedrive_id
    notes
    partner_pipedrive_id
    post_money
    ARR
    created_at
    updated_at
  }
`

const measurementFields = gql`
  fragment MeasurementFields on measurements {
    id
    metric
    value
    starts_on
    ends_on
    measurement_annotation {
      id
      annotation
    }
  }
`

const metricFields = gql`
  fragment MetricFields on metrics {
    id
    name
    full_name
    financial
    units_short
    units
  }
`

export default {
  financingEventFields,
  measurementFields,
  metricFields,
}
