// break array into chunks with a fixed number of elements each
// ex: chunkArrray([...], 5) => [[...],[...],[...]]
export function chunkArray(arr, size) {
  const chunks = []
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size))
  }
  return chunks
}

// remove any instances of null or undefined from an array set
export function removeNulls(arr) {
  return arr.filter((element) => element != null)
}

// given an <array> of objects which may include duplicates, return
// a unique set of objects using <property> as the key for uniqueness.
export function uniqueByProperty(array, property) {
  return [...new Map(array.map((item) => [item[property], item])).values()]
}
