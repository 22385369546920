// depending on which representation is fetched, email can be a string
// or an object with a set of emails. this will return the right string
// regardless
export const personEmail = function (person) {
  return typeof person.email === "object"
    ? person.email.filter((e) => e.primary)[0].value
    : person.email
}

// url for the person's custom picture or a default image
export const personPictureUrl = function (person) {
  // despite the name, picture_id is inconsistent in format. most of the time it is
  // an object with a bunch of metadata, including the profile image. occasionally
  // its value is a simple int though. only use it if we get the metadata version.
  if (person?.picture_id?.pictures) return person.picture_id.pictures["128"]
  // depending on how the person is fetched from pipedrive's API the fields can vary.
  // sometimes we need to retrieve from pictures instead of picture_id
  if (person?.pictures && person.pictures.length) return person.pictures[0].url
  // if no image is available, use default
  return "/img/avatar_120x120.png"
}

export default {
  personEmail,
}
