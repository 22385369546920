<template>
  <div class="tabs is-small">
    <ul>
      <li v-for="tab in tabs" :key="tab?.key || 0" :class="tabClass(tab)">
        <a @click="selectedTab = tab || tab?.key">{{ tab?.label || tab }}</a>
      </li>
      <li
        v-if="showAll && tabs.length > 1"
        :class="{ 'is-active': 'all' === selectedTab }"
        class="show-all"
      >
        <a @click="selectedTab = 'all'">Show all</a>
      </li>
    </ul>
  </div>
</template>

<script>
// A simple tab bar component.
//
// With string tabs:
// <TabBar v-model="selectedTab" :tabs="['Notes', 'Metrics']" />
//
// With object tabs:
// <TabBar v-model="selectedTab" :tabs="tabs" show-all />
// tabs: [{ key: 'activity', label: 'Activity Notes' }]
//
// If v-model is not used, the selected tab is not tracked
// and all tabs are shown as active.
export default {
  props: {
    // v-model binding for the selected tab
    modelValue: {
      type: [String, Object],
      required: false,
      default: null,
    },
    // Array of tabs to show { key: 'activity', label: 'Activity Notes' }
    tabs: {
      type: Array,
      required: true,
    },
    // Whether to show a right aligned "Show all" tab
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    // The selected tab
    selectedTab: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit("update:modelValue", value)
      },
    },
  },
  methods: {
    tabClass(tab) {
      return {
        tab: true,
        "is-active":
          tab == this.selectedTab || tab?.key === this.selectedTab || this.selectedTab == null,
      }
    },
  },
}
</script>

<style lang="sass">
.tabs .show-all
  margin-left: auto
</style>
