<template>
  <th @click="sort">
    <span v-if="active" class="sort-icon" v-html="sortIcon"></span>
    <slot></slot>
  </th>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  emits: ["sort"],
  data() {
    return {
      ascending: true,
    }
  },
  computed: {
    active() {
      return this.selected === this.field
    },
    sortIcon() {
      return this.ascending ? "&darr;" : "&uarr;"
    },
  },
  methods: {
    sort() {
      if (!this.active) {
        this.ascending = true
      } else {
        this._toggleSortDirection()
      }
      this.$emit("sort", this.field, this.ascending)
    },
    _toggleSortDirection() {
      this.ascending = !this.ascending
    },
  },
}
</script>

<style lang="sass" scoped></style>
