export const forGTM = (goToMarket) => {
  if (goToMarket === "enterprise") {
    return ["MQLs", "SQLs", "evaluations", "contracts", "closed"]
  } else if (goToMarket === "freemium") {
    return ["uniques", "signups", "activations", "conversions"]
  } else {
    // default bottoms-up trial funnel: 'free_trial'
    return ["uniques", "trials", "activations", "conversions"]
  }
}

export default {
  forGTM,
}
