import gql from "graphql-tag"

export const ALL_TRANSCRIPTS = gql`
  query allTranscripts {
    transcripts {
      id
      cal_id
      calendar_id
      title
      summary {
        gist
      }
    }
  }
`

// Unfortunately, the fireflies API doesn't provide a way to find transcripts
// by the id of the calendar event.  We can only search by date range.  It can
// take a long time to load lots of fields for a list of transcripts (and many
// transcripts won't match to any calendar event ids). To speed up the query
// we can just get the cal_id and id fields.  If the cal_id matches a calendar
// event id then we can use the id to get the rest of the transcript data.
// The date parameters accepts a date-time string in the ISO 8601 format,
// specifically in the form YYYY-MM-DDTHH:mm.sssZ.
// There are two id fields in fireflies, cal_id and calendar_id. According to
// the fireflies documentation:
//  - calender_id: "Calendar provider event ID. This field represents calId for
//    google calendar and iCalUID for outlook calendar."
//  - cal_id: "Calendar provider event ID with a timestamp that helps uniquely
//    identify recurring events"
export const TRANSCRIPTS_BY_DATE = gql`
  query transcripts($fromDate: DateTime, $toDate: DateTime) {
    transcripts(fromDate: $fromDate, toDate: $toDate) {
      id
      cal_id
      calendar_id
      title
    }
  }
`

// This query is used to get the list of users in the fireflies account to be
// able to match speakers in the transcript to Heavybit partners and staff.
export const FIREFLIES_USERS = gql`
  query heavybitSpeakers {
    heavybitSpeakers: users {
      email
      name
    }
  }
`

export const TRANSCRIPT_BY_ID = gql`
  query transcript($id: String!) {
    transcript(id: $id) {
      id
      transcript_url
      audio_url
      title
      cal_id
      calendar_id
      calendar_type
      summary {
        shorthand_bullet
        keywords
        gist
      }
      sentences {
        text
        start_time
        end_time
        speaker_name
      }
    }
  }
`

export const DETAILED_TRANSCRIPTS_BY_DATE = gql`
  query transcriptsByDate($fromDate: DateTime, $toDate: DateTime) {
    transcripts(fromDate: $fromDate, toDate: $toDate) {
      id
      cal_id
      calendar_id
      title
      dateString
      transcript_url
      summary {
        gist
      }
    }
  }
`
