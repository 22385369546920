<template>
  <a
    :href="href"
    :class="{ button: !inline, 'is-inline': inline, 'is-small': small, 'is-disabled': disabled }"
    target="_blank"
    class="to-pipedrive"
    title="View on pipedrive"
  >
    <span class="icon">
      <img src="@/assets/images/pipedrive_logo.svg" />
    </span>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: "https://heavybit.pipedrive.com",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
a.to-pipedrive
  img
    position: relative
    top: 2px
    width: 15px
a.to-pipedrive.is-small
  img
    top: 1px
    width: 8px
a.to-pipedrive.is-inline
  border: none
  display: inline-block !important
  opacity: 0.7 !important
  position: relative
  top: 2px
  left: -2px
  &:hover
    opacity: 1.0 !important
a.to-pipedrive.is-inline.is-small
  top: 0px
</style>
