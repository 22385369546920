const sortByDate = (a, b) => {
  if (a.ends_on > b.ends_on) return 1
  if (a.ends_on < b.ends_on) return -1
  return 0
}

export const lastMetricMeasurement = (measurements, metric) => {
  return lastValues(measurements, metric, 1)[0]
}

export const lastMetricValue = (measurements, metric) => {
  const lastMeasurement = lastMetricMeasurement(measurements, metric)
  return lastMeasurement && lastMeasurement.value
}

// return the last <count> measurements for a given metric
export const lastValues = (measurements, metricName, count = 1) => {
  return measurements
    .filter((m) => m.metric === metricName)
    .sort(sortByDate)
    .slice(-count)
}

export default {
  lastMetricValue,
  lastValues,
}
