// handles access to secrets that are shared among all users, including API keys to
// various services. these are stored in the backend but available for use once a
// user is authenticated. we don't put these in the embedded env of this app since
// the app source is available without authentication.

import { reactive } from "vue"
import UserStateService from "@/services/userStateService"
import { hasuraClient } from "@/init/apollo"
import queries from "@/common/queries"

const SharedSecretService = (function () {
  const cached = reactive({})

  function fetchRemoteSecret(key) {
    return hasuraClient
      .query({
        query: queries.fetchSharedSecret,
        variables: { name: key },
      })
      .then((result) => {
        const secrets = result.data.shared_secret
        if (!secrets.length) {
          console.log("No value could be retrieved for", key)
        } else {
          return secrets[0].value
        }
      })
      .catch((error) => {
        console.log(`Could not fetch shared secret for #{key}`, error)
      })
  }

  return {
    // get the value of a shared secret, returning from cache if available, otherwise
    // fetching from the backend
    get: async function (key) {
      await UserStateService.waitForLogin()
      if (Object.keys(cached).includes(key)) {
        return cached[key]
      } else {
        const value = await fetchRemoteSecret(key)
        cached[key] = value
        return value
      }
    },
  }
})()

export default SharedSecretService
