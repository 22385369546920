// common logic related to authorization
import { useUserStore } from "@/stores/user"

const getIdToken = function () {
  // stored by AuthService
  return localStorage.getItem("id_token")
}

// return bearer authorization header if available, otherwise empty string
export const bearerHeader = function () {
  const token = getIdToken()
  if (!token) return ""
  return `Bearer ${token}`
}

export const firefliesHeader = function () {
  // Get the API key from the user state
  const userStore = useUserStore()
  return `Bearer ${userStore.firefliesToken}`
}
