<template>
  <div class="hero is-fullheight">&nbsp;</div>
</template>

<script>
import auth from "@/services/authService"

export default {
  created() {
    auth.addListener("authenticate", this.onAuthenticate)
    auth.checkAuthentication()
  },
  unmounted() {
    auth.removeListener("authenticate", this.onAuthenticate)
  },
  methods: {
    onAuthenticate(e) {
      // redirect back to where they were trying to go when logging in
      this.$router.push(e.detail?.state?.target || "/")
    },
  },
}
</script>

<style lang="sass" scoped></style>
