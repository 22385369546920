import { defineStore } from "pinia"

// shared state for calendar view / components

export const useCalendarStore = defineStore("calendar", {
  state: () => ({
    activities: [],
  }),

  getters: {},

  actions: {
    addActivity(activity) {
      this.activities.push(activity)
    },
    setActivities(activities) {
      if (!Array.isArray(activities)) throw "Non-array value submitted for activities"
      this.$patch({ activities })
    },
    updateActivity(activity) {
      const index = this.activities.findIndex((a) => a.id == activity.id)
      if (index != -1) {
        this.activities.splice(index, 1, activity)
      }
    },
  },
})
