<template>
  <div>
    <div v-if="showForm" class="field has-addons">
      <div class="control autocomplete2">
        <AutocompleteInput
          ref="autocomplete"
          placeholder="Search for metric"
          :results="metricResults"
          @search="findMetrics"
          @select="selectMetric"
        />
      </div>
      <div class="control">
        <a
          class="button"
          :class="{ disabled: addDisabled, 'is-info': !addDisabled }"
          @click="addMetric"
        >
          Add
        </a>
      </div>
    </div>
    <a v-if="!showForm" class="toggle-add button" @click="toggleForm">+ Add metric</a>
  </div>
</template>

<script>
import AutocompleteInput from "@/components/AutocompleteInput.vue"
import queries from "@/common/queries"
import mutations from "@/common/mutations"
import { useMemberStore } from "@/stores/member"

export default {
  components: { AutocompleteInput },
  props: {
    memberId: {
      type: Number,
      default: null,
    },
    exclusions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const memberStore = useMemberStore()
    return { memberStore }
  },
  data() {
    return {
      currentMetric: null,
      metricResults: [], // review below for removal
      showForm: false,
    }
  },
  apollo: {
    metrics: {
      query: queries.metrics,
    },
  },
  computed: {
    addDisabled() {
      return !this.currentMetric
    },
    excludedMetrics() {
      return this.exclusions.map((e) => e.name)
    },
  },
  methods: {
    addMetric() {
      if (this.addDisabled) return
      const metric_id = this.currentMetric.id
      const member_id = this.memberId
      this.$apollo
        .mutate({
          mutation: mutations.addMetric,
          variables: {
            objects: [{ member_id, metric_id }],
          },
          update: () => {
            this.$toasted.success("Metric added.")
            this.clearMetric()
            this.memberStore.triggerRefresh()
          },
        })
        .catch((err) => {
          console.log(err)
          this.$toasted.error("Error adding metric, check console.")
        })
    },
    clearMetric() {
      this.currentMetric = null
      this.$refs.autocomplete.clear()
    },
    findMetrics(input) {
      const search = input.toLowerCase()
      if (!search.length) {
        this.metricResults = []
      } else {
        this.metricResults = this._metricMatches(search)
          .map((m) => [m.id, m.full_name])
          .sort((a, b) => a[1].localeCompare(b[1]))
      }
      console.log("results", this.metricResults)
    },
    selectMetric(input) {
      // console.log("select", input);
      this.currentMetric = this.metrics.find((m) => m.id == input[0])
    },
    toggleForm() {
      this.showForm = !this.showForm
      if (this.showForm) {
        this.$nextTick(() => this.$refs.autocomplete.focus())
      }
    },
    _metricMatches(search) {
      return this.metrics
        .filter((m) => !this.excludedMetrics.includes(m.name))
        .filter(
          (m) =>
            m.full_name.toLowerCase().indexOf(search) > -1 ||
            m.name.toLowerCase().indexOf(search) > -1
        )
    },
  },
}
</script>

<style lang="sass" scoped>
.autocomplete2
  width: 300px
.add-metric-input
  width: 300px
.autocomplete
  position: relative
.autocomplete-results
  position: absolute
  width: 300px
  padding: 0
  margin: 0
  border: 1px solid #eee
  height: 12rem
  overflow: auto
  background: #fff
.autocomplete-result
  padding: 4px 6px
  cursor: pointer
  &.is-active
    background: #d0eafb
.disabled
  cursor: not-allowed
  color: #bbb
input.add-metric-input:focus
  border-color: #b5b5b5
  box-shadow: inherit
</style>
