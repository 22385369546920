<template>
  <section class="company-holdings section">
    <div class="toolbar">
      <h2 class="title">Holdings</h2>
      <div class="tools">
        <div class="field">
          <label class="field-label is-small has-text-weight-semibold">Group By:</label>
          <div class="select is-small">
            <select v-model="selectedGroup" selected="none">
              <option v-for="option in groupOptions" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <button class="button is-small" @click="download">Download</button>
      </div>
    </div>

    <table id="holdings" class="table is-fullwidth">
      <thead class="is-size-7">
        <tr>
          <th v-if="isGrouped" class="has-text-left">{{ $filters.capitalize(selectedGroup) }}</th>
          <th v-else class="has-text-left">Investment</th>
          <th class="has-text-left">Details</th>
          <th>Period</th>
          <th>Program Equity</th>
          <th>Program Value</th>
          <th>Direct Investment</th>
          <th>Total Value</th>
        </tr>
      </thead>

      <tbody v-if="!investments">
        <!-- No results -->
        <tr>
          <td colspan="7" class="disabled has-text-centered">
            Unable to find investments with organization ID '{{ org_id }}'
          </td>
        </tr>
      </tbody>

      <!-- Grouped investments -->
      <tbody v-for="group in groups" v-else-if="isGrouped" :key="group.id">
        <tr v-for="(investment, index) in group.investments" :key="investment.id">
          <td v-if="index == 0" :rowspan="group.investments.length" class="has-text-left">
            {{ group.name }}
            <p class="is-size-7 has-text-grey-light has-text-weight-normal">
              ${{ (group.total / 1e6).toFixed(2) }}m,
              {{ $filters.percentage((group.total / total_invested) * 100) }}
              <br />
              <span v-if="group.investments.length > 3">
                {{ group.investments.length }} investments
              </span>
            </p>
          </td>
          <td class="has-text-left">
            <a target="_blank" class="has-text-dark" :href="pipedriveUrl(investment.id)">{{
              investment.title
            }}</a>
            <p class="is-size-7 has-text-grey" :class="{ 'is-italic': !description(investment) }">
              {{ description(investment) || "No description" }}
            </p>
          </td>
          <td>{{ $filters.quarter(investment.won_time) }}</td>
          <td>{{ $filters.percentage(programEquity(investment)) }}</td>
          <td>{{ currencyRounded(programValue(investment)) }}</td>
          <td>{{ currencyRounded(amountInvested(investment)) }}</td>
          <td>{{ currencyRounded(totalValue(investment)) }}</td>
        </tr>
        <tr></tr>
      </tbody>

      <!-- Ungrouped investments -->
      <tbody v-else>
        <tr v-for="investment in investments" :key="investment.id">
          <td class="has-text-left">
            <a target="_blank" class="has-text-dark" :href="pipedriveUrl(investment.id)">
              {{ investment.title }}
            </a>
          </td>
          <td class="is-size-7 has-text-left">
            <p class="has-text-grey">{{ description(investment) || "No description" }}</p>
            <p class="has-text-grey-light">{{ vehicle(investment) }}</p>
          </td>
          <td>{{ $filters.quarter(investment.won_time) }}</td>
          <td>{{ $filters.percentage(programEquity(investment)) }}</td>
          <td>{{ currencyRounded(programValue(investment)) }}</td>
          <td>{{ currencyRounded(amountInvested(investment)) }}</td>
          <td>{{ currencyRounded(totalValue(investment)) }}</td>
        </tr>
      </tbody>

      <tfoot>
        <tr style="border-bottom: none">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ currencyRounded(total_invested) }}</th>
          <th>{{ currencyRounded(total_value) }}</th>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import { fetchDealTypes, fetchTransactionTypes } from "@/common/deals"
import { currencyRounded } from "@/common/filters"
import pipedrive from "@/common/pipedrive"
import { downloadCSVFromTable } from "@/utils/csv"

export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      txTypes: [],
      allTypes: [],
      investments: [],
      groupOptions: [
        { value: "", text: "None" },
        { value: "fund", text: "Fund" },
        { value: "round", text: "Financing Round" },
        { value: "type", text: "Transaction Type" },
      ],
      selectedGroup: "",
    }
  },
  computed: {
    isGrouped() {
      return this.selectedGroup && this.groups.length > 0
    },
    groups() {
      if (!this.investments || this.investments.length == 0) return []
      switch (this.selectedGroup) {
        case "fund":
          return this.groupsByFund()
        case "round":
          return this.groupsByRound()
        case "type":
          return this.groupsByTxType()
        default:
          return []
      }
    },
    total_value: function () {
      return this.investments.reduce((sum, deal) => sum + deal.value, 0)
    },
    total_invested: function () {
      return this.investments.reduce((sum, deal) => sum + this.amountInvested(deal), 0)
    },
  },
  created() {
    this.fetchFields()
    this.fetchTransactionTypes()
    this.fetchDeals()
  },
  methods: {
    currencyRounded,
    fetchFields() {
      fetchDealTypes().then((dealTypes) => (this.allTypes = dealTypes))
    },
    fetchTransactionTypes() {
      fetchTransactionTypes().then((types) => (this.txTypes = types))
    },
    fetchDeals() {
      const params = { status: "won", only_primary_association: 1 }

      pipedrive.api
        .get(`organizations\\${this.id}\\deals`, params)
        .then(
          (response) => (this.investments = response.data.data.filter((x) => x.pipeline_id === 1))
        )
        .catch((err) => console.log(err))
    },
    groupsByFund() {
      const deals = this.investments || []
      const fundKey = "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2"
      const fundIds = [...new Set(deals.map((i) => i[fundKey].value))]
      const funds = fundIds.map((id) => {
        const fund = deals.find((d) => id === d[fundKey]?.value)[fundKey]
        const investments = deals.filter((d) => id === d[fundKey]?.value)
        const total = this.totalInvested(investments)
        return { id, name: fund.name, investments, total }
      })

      return funds.sort((a, b) => a.name?.localeCompare(b.name))
    },
    groupsByRound() {
      const typeKey = "78935a8811a83c5fdaedf5aa4f41dcf6569e4a91"
      const deals = this.investments || []
      const typeIds = [...new Set(this.investments.map((i) => i[typeKey]))]
      const types = typeIds.map((id) => ({ id: id, name: this.allTypes[id] }))

      return types.map((type) => {
        const investments = deals.filter((d) => d[typeKey] == type.id) || []
        const total = this.totalInvested(investments)
        return { ...type, investments, total }
      })
    },
    groupsByTxType() {
      const typeKey = "2672757793f351cb3e1a8f40795c22d3dce97f9c"
      const deals = this.investments || []
      const typeIds = [...new Set(this.investments.map((i) => i[typeKey]))]
      const txTypes = typeIds.map((id) => ({ id: id, name: this.txTypes[id] }))

      return txTypes.map((type) => {
        const investments = deals.filter((d) => d[typeKey] == type.id) || []
        const total = this.totalInvested(investments)
        return { ...type, investments, total }
      })
    },
    pipedriveUrl(id) {
      return pipedrive.web.dealUrl(id)
    },
    vehicle(investment) {
      const key = "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2"
      return investment[key] ? investment[key].name : ""
    },
    description(investment) {
      return investment["3a8519aea381ce69742a53f5010f0d3f9cb22201"]
    },
    totalInvested(investments) {
      return investments.reduce((sum, deal) => sum + this.amountInvested(deal), 0)
    },
    amountInvested(investment) {
      // If the deal has no program equity then the investment value field
      // can be used, otherwise the "investment" field needs to be used instead
      // because the value field should equal the program equity + investment
      if (!this.programEquity(investment)) return investment.value
      return investment["49f935d19c9d29dc1ab5552fad455069b32b3017"]
    },
    programEquity(investment) {
      return investment["fdef8d9342497889c83924ef6cb4b282d7dbb9d4"]
    },
    programValue(investment) {
      return investment.value - this.amountInvested(investment)
    },
    totalValue(investment) {
      return investment.value
    },
    download() {
      downloadCSVFromTable("holdings")
    },
  },
}
</script>

<style lang="sass" scoped>
table#holdings
  font-size: 0.9rem
  text-align: right
  th
    min-width: 5rem !important
    vertical-align: bottom
  td.group
    font-weight: 600
    width: 11rem
    height: 3rem
  td:nth-child(n+3),
  th:nth-child(n+3)
    max-width: 6rem
</style>
