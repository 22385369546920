import { watch } from "vue"
import { useGoogleStore } from "@/stores/google"
import { useUserStore } from "@/stores/user"

// ensure GIS is loaded an return reference
export const ensureGISLoaded = function () {
  return new Promise((resolve) => {
    const googleStore = useGoogleStore()
    if (googleStore.librariesLoaded) resolve(window.google.accounts)
    watch(
      () => googleStore.librariesLoaded,
      (loaded) => loaded && resolve(window.google.accounts)
    )
  })
}

// ensure initial google loading is complete, including requesting
// current access token and processing the result
export const ensureGoogleReady = function () {
  return new Promise((resolve) => {
    const googleStore = useGoogleStore()
    if (googleStore.ready) resolve()
    watch(
      () => googleStore.ready,
      (ready) => {
        if (ready) resolve()
      }
    )
  })
}

// ensure user is loaded and email is available
export const getUserEmail = function () {
  return new Promise((resolve) => {
    const userStore = useUserStore()
    if (userStore.email) resolve(userStore.email)
    watch(
      () => userStore.email,
      (email) => {
        if (email) resolve(email)
      }
    )
  })
}
