<template>
  <span v-if="eventCategory" class="tag event-category selected" :class="eventCategory">
    <span>{{ eventCategory }}</span>
    <button class="delete is-small" @click="setEventCategory(null)"></button>
  </span>
  <template v-else>
    <span
      v-for="category in categories"
      :key="category"
      class="tag event-category"
      :class="category"
      @click="setEventCategory(category)"
    >
      {{ category }}
    </span>
  </template>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  emits: ["set-category"],
  data() {
    return {
      categories: ["portfolio", "pipeline", "networking", "management", "personal", "other"],
    }
  },
  computed: {
    eventCategory() {
      if (!this.event.extendedProperties || !this.event.extendedProperties.private) return null
      return this.event.extendedProperties.private.eventCategory
    },
  },
  methods: {
    setEventCategory(category) {
      this.$emit("set-category", category)
    },
  },
}
</script>

<style lang="sass">
.event-category
  &.tag.selected
    font-weight: 600
  &.personal:hover,
  &.personal.selected
    color: white
    background: slateblue
  &.portfolio:hover,
  &.portfolio.selected
    color: white
    background: mediumseagreen
  &.pipeline:hover,
  &.pipeline.selected
    color: white
    background: orange
  &.networking:hover,
  &.networking.selected
    color: white
    background: lightskyblue
  &.management:hover,
  &.management.selected
    color: white
    background: cadetblue
  &.other:hover,
  &.other.selected
    color: white
    background: darkturquoise
</style>
