// common methods for accessing google calendar APIs

export const getEvent = function (client, calendarId, eventId) {
  const params = {
    calendarId,
    eventId,
  }

  return client.calendar.events.get(params)
}

export const listEvents = function (client, calendarId, timeMin, timeMax) {
  const params = {
    calendarId,
    orderBy: "startTime",
    singleEvents: true,
    maxAttendees: 30,
    maxResults: 100,
    // timeMin: "2019-10-09T10:00:00-07:00"
    timeMin,
    timeMax,
  }

  return client.calendar.events.list(params)
}

export const updateEvent = function (client, calendarId, eventId, fields = {}) {
  const params = {
    calendarId,
    eventId,
    resource: fields,
  }

  return client.calendar.events.patch(params)
}

export default {
  getEvent,
  listEvents,
  updateEvent,
}
