<template>
  <div class="is-relative">
    <VueDatePicker v-model="value" :auto-apply="true" :clearable="false" month-picker />
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import { toDate } from "@/utils/date"

export default {
  components: { VueDatePicker },
  props: {
    modelValue: {
      // accepts a js date object or YYYY-MM-DD datestring
      type: [Date, String],
      default: () => new Date(),
    },
  },
  // emits a YYYY-MM-DD datestring (first day of selected month)
  emits: ["update:modelValue", "update"],
  data() {
    return {}
  },
  computed: {
    value: {
      get() {
        const date = toDate(this.modelValue)
        return { month: date.getMonth(), year: date.getFullYear() }
      },
      set(value) {
        // const newDate = new Date(value.year, value.month, 1);
        const month = (value.month + 1).toString().padStart(2, "0")
        const newDate = `${value.year}-${month}-01`
        this.$emit("update:modelValue", newDate)
        this.$emit("update", newDate)
      },
    },
  },
  methods: {},
}
</script>

<style lang="sass" scoped></style>
