<template>
  <div>Uh-oh!</div>
</template>

<script>
class RandomError extends Error {
  constructor(message) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
    Object.defineProperty(this, "name", { value: "RandomError" })
  }
}

export default {
  mounted() {
    this.throwError()
  },
  methods: {
    throwError() {
      setTimeout(() => {
        const randomNum = Math.round(Math.random() * 1000)
        throw new RandomError(`Uh-oh, error ${randomNum}`)
      }, 500)
    },
  },
}
</script>

<style lang="sass" scoped></style>
