<template>
  <td ref="enclosing" :class="{ annotated: hasAnnotation }" @click="editCell">
    <span v-show="!editing" class="formatted" :class="{ 'has-text-grey-light': !formattedValue }">
      {{ formattedValue || "-" }}
    </span>
    <input
      v-show="editing"
      ref="inputField"
      v-model="localValue"
      type="text"
      :class="{ invalid: !validFormat }"
      @keyup.enter.exact="commitEdit"
      @keydown.tab.exact.stop.prevent="commitAndNext"
      @keydown.tab.shift.stop.prevent="commitAndPrevious"
      @keyup.shift.enter="commitAndDown"
      @keyup.esc.exact="cancelEdit"
    />
  </td>
</template>

<script>
import pick from "lodash/pick"
import { isNumeric, toRawNumber } from "@/utils/number"
import { commafy, currencyDollars } from "@/utils/numberFormatter"
import queries from "@/common/queries"
import mutations from "@/common/mutations"

export default {
  props: {
    measurement: {
      type: Object,
      required: true,
    },
    metric: {
      type: Object,
      required: true,
    },
    editingId: {
      type: String,
      default: "",
    },
    virtualId: {
      type: String,
      default: "",
      required: true,
    },
    memberId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  emits: ["edit-cell", "move-measurement"],
  data() {
    return {
      committing: false,
      editing: false,
      last: null,
      localValue: null,
    }
  },
  computed: {
    annotation() {
      return (
        this.measurement &&
        this.measurement.measurement_annotation &&
        this.measurement.measurement_annotation.annotation
      )
    },
    formattedValue() {
      if (!this.measurement || !isNumeric(this.localValue)) return ""
      if (this.metric && this.metric.financial) {
        return currencyDollars(this.localValue)
      }
      return commafy(this.localValue)
    },
    hasAnnotation() {
      return !!this.annotation
    },
    rawValue() {
      return toRawNumber(this.localValue) // strip commas and $
    },
    validFormat() {
      return ["", null, "$"].includes(this.localValue) || isNumeric(this.rawValue)
    },
  },
  watch: {
    editingId: function (newVal, oldVal) {
      if (newVal !== this.virtualId && this.editing) {
        // another cell just started editing, so save and close this one
        if (!this.committing) this.commitEdit()
        this.editing = false
      } else if (oldVal !== this.virtualId && newVal === this.virtualId) {
        // we should start editing our cell
        if (!this.editing) this.startEdit({ noEvent: true })
      }
    },
    measurement: function (newVal) {
      // keep local value in sync if parent state changes
      if (this.localValue !== newVal.value) {
        this.localValue = newVal.value
      }
    },
  },
  mounted() {
    // initial sync to localValue
    if (this.measurement) {
      this.localValue = this.measurement.value
    }
  },
  methods: {
    cancelEdit() {
      this.localValue = this.last
      this.editing = false
    },
    commitEdit() {
      this.editing = false
      if (this.rawValue === this.last) return // unchanged
      // console.log(this.localValue);
      this.committing = true
      if (this.measurement.id) {
        if (isNumeric(this.rawValue)) {
          this.updateMeasurement()
        } else {
          this.deleteMeasurement()
        }
      } else {
        if (isNumeric(this.rawValue)) {
          this.createMeasurement()
        } else {
          // garbage input, maybe show validation help later
          this._resetCommitState()
        }
      }
    },
    commitAndDown() {
      this.commitEdit()
      this.$emit("move-measurement", { direction: "down", from: this.virtualId })
    },
    commitAndNext() {
      this.commitEdit()
      this.$emit("move-measurement", { direction: "right", from: this.virtualId })
    },
    commitAndPrevious() {
      this.commitEdit()
      this.$emit("move-measurement", { direction: "left", from: this.virtualId })
    },
    editCell() {
      if (!this.editing) this.startEdit()
    },
    startEdit(opts = {}) {
      // cache for comparison
      this.last = this.localValue
      this.editing = true
      if (!opts.noEvent) this.$emit("edit-cell", this.virtualId)
      this.$nextTick(() => this.$refs.inputField.focus())
    },
    createMeasurement() {
      this._cleanInputValue()
      this.$apollo.mutate({
        mutation: mutations.createMeasurement,
        variables: {
          objects: [this._createProps()],
        },
        // add returned item to existing apollo cache; insert_measurements is the
        // key for data we care about in the response payload
        update: (cache, { data: { insert_measurements } }) => {
          const query = queries.memberMeasurements
          const variables = { id: this.memberId }
          let data = cache.readQuery({ query, variables })
          const measurements = [...data.measurements, insert_measurements.returning[0]]
          cache.writeQuery({ query, variables, data: { ...data, measurements } })
          this._resetCommitState()
        },
      })
    },
    deleteMeasurement() {
      const removeId = this.measurement.id
      this.$apollo.mutate({
        mutation: mutations.deleteMeasurement,
        variables: {
          id: removeId,
        },
        // remove from local cache
        update: (cache) => {
          const identifier = cache.identify({ __typename: "measurements", id: removeId })
          cache.evict({ id: identifier })
          cache.gc()
          this._resetCommitState()
        },
      })
    },
    updateMeasurement() {
      this._cleanInputValue()
      this.$apollo.mutate({
        mutation: mutations.updateMeasurement,
        variables: {
          id: Number(this.measurement.id),
          value: this.localValue,
        },
        // local cache is automatically updated with returned instance
        update: (_cache) => {
          this._resetCommitState()
        },
      })
    },
    _cleanInputValue() {
      this.localValue = toRawNumber(this.localValue)
    },
    _createProps() {
      let props = pick(this.measurement, ["metric", "starts_on", "ends_on"])
      props.member_id = this.memberId
      props.value = this.rawValue
      return props
    },
    _resetCommitState() {
      if (!this.editing) {
        // be careful about rapid edits, could have re-opened
        this.last = null
      }
      this.committing = false
    },
  },
}
</script>

<style lang="sass" scoped>
td.annotated
  background: linear-gradient(45deg, #fff, #fff 91.5%, green 93%)

span
  border-bottom: 1px solid transparent
  display: block
input
  width: 100%
  box-sizing: border-box
  font-size: 0.9rem
  text-align: right

  // make input look like normal table field
  border: none
  border-bottom: 1px solid transparent
  padding: 0
  margin: 0

  // inherit typography
  font: inherit
  color: inherit
  line-height: inherit
  font-size: inherit
  text-align: inherit

input:focus
  // disable glow
  outline: none
  border-bottom: 1px solid lightblue
  background: none

input.invalid
  background: hsl(348, 100%, 81%)
</style>
