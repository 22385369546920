<template>
  <div class="fund">
    <section id="fund-header" class="section">
      <div class="level toolbar">
        <div class="level-left">
          <SelectHeader
            :options="funds"
            title-key="name"
            value-key="slug"
            :selected="slug"
            @select="selectFund"
          />
        </div>
        <div class="level-right">
          <PipedriveButton v-show="fund?.id" :href="organizationUrl" />
        </div>
      </div>
      <StatsBar :stats="stats" />
    </section>

    <section id="fund-tabs" class="section">
      <div class="tabs">
        <ul>
          <li v-for="tab in tabs" :key="tab.key" :class="{ 'is-active': selectedTab === tab.key }">
            <a @click="selectedTab = tab.key">{{ tab.title }}</a>
          </li>
        </ul>
      </div>

      <div v-show="selectedTab == 'portfolio' || selectedTab === 'all'" class="tab">
        <FundPortfolio :companies="companies" />
      </div>

      <div v-show="selectedTab == 'deals' || selectedTab === 'all'" class="tab">
        <FundInvestments :investments="investments" />
      </div>

      <div v-show="selectedTab === 'lps' || selectedTab === 'all'" class="tab">
        <FundCommitments :commitments="commitments" />
      </div>

      <!-- <div class="tab" v-show="selectedTab === 'ppl' || selectedTab === 'all'">
        <FundInvestors :investors="investors" />
      </div> -->

      <div v-show="selectedTab === 'program' || selectedTab === 'all'" class="tab">
        <FundProgramHistory :investments="investments" />
      </div>

      <div v-show="selectedTab === 'pipeline' || selectedTab === 'all'" class="tab">
        <FundPipeline :pipedrive-id="fund?.id" />
      </div>
    </section>
    <br />
  </div>
</template>

<script>
import PipedriveButton from "@/components/PipedriveButton.vue"
import SelectHeader from "@/components/SelectHeader.vue"
import StatsBar from "@/components/StatsBar.vue"

import FundPortfolio from "@/components/FundPortfolio.vue"
import FundInvestments from "@/components/FundInvestments.vue"
import FundCommitments from "@/components/FundCommitments.vue"
// import FundInvestors from "@/components/FundInvestors.vue";
import FundProgramHistory from "@/components/FundProgramHistory.vue"
import FundPipeline from "@/components/FundPipeline.vue"

import pipedrive from "@/common/pipedrive"
import { deals, organizations } from "@/common/pipedrive/resources"

export default {
  components: {
    PipedriveButton,
    SelectHeader,
    StatsBar,
    FundPortfolio,
    FundInvestments,
    FundCommitments,
    // FundInvestors,
    FundProgramHistory,
    FundPipeline,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fundKey: "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2",
      fund: null,
      funds: [],
      allCompanies: [],
      allInvestments: [],
      allCommitments: [],
      allInvestors: [],
      allDeals: [],
      tabs: [
        { key: "portfolio", title: "Portfolio" },
        { key: "deals", title: "Investments" },
        { key: "lps", title: "Commitments" },
        // { key: "ppl", title: "Investors" },
        { key: "program", title: "Program Equity" },
        // { key: "reports", title: "Reporting" },
        { key: "pipeline", title: "Pipeline" },
        { key: "all", title: "Show All" },
      ],
      selectedTab: "portfolio",
    }
  },
  computed: {
    stats() {
      let totalCommitted = (this.totalCommitted / 1e6).toFixed(1)
      let totalInvested = (this.totalInvested / 1e6).toFixed(1)
      let rounding = this.averageInvested > 1e5 ? 2 : 0
      let avgInvestment = (this.averageInvested / 1e6).toFixed(rounding)
      return [
        { title: "Total Committed", value: `$${totalCommitted}m` },
        { title: "Total Invested", value: `$${totalInvested}m` },
        { title: "Avg. Investment", value: `$${avgInvestment}m` },
        { title: "Investments", value: this.investments?.length || 0 },
        { title: "Companies", value: this.companies?.length || 0 },
      ]
    },
    investments() {
      if (!this.fund || this.slug === "all") return this.allInvestments
      return this.allInvestments.filter((i) => i[this.fundKey]?.value == this.fund.id)
    },
    commitments() {
      if (!this.fund || this.slug === "all") return this.allCommitments
      return this.allCommitments.filter((i) => i[this.fundKey]?.value == this.fund.id)
    },
    companies() {
      // Create an array of unique org ids from the current fund's investments
      let ids = [...new Set(this.investments.map((i) => i.org_id.value))]
      // Create array of companies from the ids in the list
      return this.allCompanies.filter((org) => ids.includes(org.id))
    },
    deals() {
      if (!this.fund || this.slug === "all") return this.allDeals
      return this.allDeals.filter((d) => d[this.fundKey] == this.fund.id)
    },
    investors() {
      // Create an array of unique person ids from the current fund's commitments
      let ids = [...new Set(this.commitments.flatMap((deal) => this.personIds(deal)))]
      return this.allInvestors.filter((person) => ids.includes(person.id))
    },
    totalCommitted() {
      // const key = "49f935d19c9d29dc1ab5552fad455069b32b3017"; // Amount invested field
      return this.commitments.reduce((sum, investment) => (sum += investment.value), 0)
    },
    totalInvested() {
      const amount = "49f935d19c9d29dc1ab5552fad455069b32b3017" // Amount invested field
      const equity = "fdef8d9342497889c83924ef6cb4b282d7dbb9d4" // Program equity field
      // If the program equity field isn't set, defer to the value field for amount invested
      return this.investments.reduce((sum, i) => (sum += i[equity] ? i[amount] : i.value), 0)
    },
    averageInvested() {
      return this.investments.length == 0 ? 0 : this.totalInvested / this.investments.length
    },
    organizationUrl() {
      if (!this.fund || this.slug === "all") return ""
      return pipedrive.web.organizationUrl(this.fund.id)
    },
    summary() {
      return this.fund["426a3fe18ecb9344e548ee31614be1dd905d1920"]
    },
    driveFolder() {
      return this.fund["fe4bc918a3bc8bedd077f9896ffd23781317d936"]
    },
  },
  watch: {
    slug() {
      // Update the fund when the slug from the URL changes
      const fund = this.funds.find((f) => f.slug == this.slug)
      this.fund = fund || this.funds[0] // Default to the first fund if not found
    },
  },
  created() {
    const localFunds = sessionStorage.getItem("funds")
    if (localFunds) {
      this.funds = JSON.parse(localFunds)
      const fund = this.funds.find((f) => f.slug == this.slug)
      this.fund = fund || this.funds[0] // Default to the first fund if not found
    } else {
      this.fetchFunds()
    }

    this.fetchCompanies()
    this.fetchInvestments()
    this.fetchCommitments()
    this.fetchDeals()
  },
  mounted() {
    this._bindKeys()
  },
  beforeUnmount() {
    this._unbindKeys()
  },
  methods: {
    selectFund(slug) {
      if (this.slug === slug) return
      this.$router.push({ name: "fund-show", params: { slug } })
    },
    nextFund(backwards = false) {
      let index = this.funds.findIndex((f) => f.slug == this.slug)
      let step = backwards ? -1 : 1
      let nextIndex = (this.funds.length + index + step) % this.funds.length

      this.selectFund(this.funds[nextIndex].slug)
    },
    fetchFunds() {
      organizations
        .byFilter(227, { sort: "add_time ASC" })
        .then((result) => {
          this.funds = [
            // Prepend a default synthetic fund for all vehicles
            {
              id: 0,
              slug: "all",
              name: "All Heavybit Vehicles",
              description: "All investment funds and vehicles",
              segment: "All",
            },
            ...result.map((fund) => {
              // Append the funds description along with the URL field as a ref for the router & slug
              return {
                id: fund.id,
                name: fund.name,
                slug: fund["b25fb441467be944f6983024dd7673be1350aa2b"] || "",
                description: fund["426a3fe18ecb9344e548ee31614be1dd905d1920"] || "",
                segment: fund["528d1845ce1cadb0a6d25069b65836d3986d00c8"] || "",
              }
            }),
          ]
          sessionStorage.setItem("funds", JSON.stringify(this.funds))
        })
        .catch((err) => {
          this.$toasted.error(`Error fetching funds: ${err}`)
          console.log(err)
        })
    },
    fetchInvestments() {
      // Fetch all won members deals for all funds and then use
      // a computed property for the specific fund that is selected
      deals
        .byFilter(170, { limit: 1000, status: "won", sort: "won_time DESC" })
        .then((response) => {
          this.allInvestments = response

          // Raise a warning if any investments aren't assigned to a fund
          const missingFunds = this.investments.filter((i) => !i[this.fundKey])
          if (missingFunds.length === 0) return
          this.$toasted.error(
            missingFunds.length === 1
              ? `${missingFunds[0].title} missing fund`
              : `${missingFunds.length} investments missing funds`
          )
        })
        .catch(() => this.$toasted.error("Can't fetch investments"))
    },
    fetchCommitments() {
      // Fetches all fund commitments
      // N.B. We can't use the pipeline endpoint as in the fetchDeals method
      // because not all historic deals were in that pipeline
      deals
        .byFilter(234, { limit: 1000, sort: "title ASC, value DESC" })
        .then((response) => {
          this.allCommitments = response

          // Raise a warning if any commitments aren't found and jump to the pipeline tab
          if (this.commitments.length === 0) {
            this.$toasted.info(`No commitments found for ${this.fund?.name || "Unknown fund"}`)
            this.selectedTab = "pipeline"
          }
        })
        .catch((error) => {
          console.log(error)
          this.$toasted.error(`Unable to fetch ${this.fund?.name || "Uknown fund's"} commitments`)
        })
    },
    fetchDeals() {
      // Fetch all deals in the LP pipeline regardless of status
      deals
        .inPipeline(17, { limit: 1000 })
        .then((response) => (this.allDeals = response))
        .catch(() => this.$toasted.error("Unable to fetch LP pipeline deals"))
    },
    personIds(deal) {
      // Given a fund deal (commitment) return a list of ids of the people associated
      // with the deal.  Because all participants aren't returned automatically we should
      // fetch the people with another call to PD. Instead, for the time being
      // just collate the persons directly connected to the deal (not all
      // participants)

      const financeContactKey = "618c26e9fab7947240d2ca50188f8c00ae618cd6"
      let ids = deal.person_id ? [deal.person_id.value] : []
      if (deal[financeContactKey]) ids.push(deal[financeContactKey].value)
      return ids
    },
    fetchCompanies() {
      // Fetch companies from all funds and filter with computed property
      organizations
        .byFilter(278, { sort: "name ASC" })
        .then((response) => (this.allCompanies = response))
        .catch(() => this.$toasted.error(`Unable to fetch ${this.fund.name} portfolio companies`))
    },
    selectTab(key) {
      switch (key) {
        case "P":
          return (this.selectedTab = this.selectedTab == "portfolio" ? "program" : "portfolio")
        case "I":
          return (this.selectedTab = this.selectedTab == "deals" ? "pipeline" : "deals")
        case "C":
          return (this.selectedTab = "lps")
        case "A":
          return (this.selectedTab = "all")
        default:
          break
      }
    },
    _bindKeys() {
      if (!this.$mousetrap) return
      ;["P", "I", "C", "A"].map((key) => this.$mousetrap.bind(key, () => this.selectTab(key)))
      this.$mousetrap.bind("j", () => this.nextFund())
      this.$mousetrap.bind("k", () => this.nextFund(true))
      this.$mousetrap.bind("h", () => this.$router.push({ name: "funds" }))
    },
    _unbindKeys() {
      if (!this.$mousetrap) return
      ;["P", "I", "E", "A"].forEach((k) => this.$mousetrap.unbind(k))
      ;["h", "j", "k"].forEach((k) => this.$mousetrap.unbind(k))
    },
  },
}
</script>

<style lang="sass">
#fund-tabs
  .tabs
    margin-bottom: 0
  ul li:last-child
      margin-left: auto
  .tab section
    padding: 1em 0em
  .tabs a
    font-weight: 400
    padding-left: 0.5rem
table
  font-size: 0.9rem
</style>
