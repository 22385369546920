<template>
  <div class="calendar-event columns">
    <div class="column is-narrow">
      <a @click="$emit('expand-event')">
        <EventDate :date="event.date || event.start.dateTime" />
      </a>
      <div v-if="recurring" class="recurrence">
        <span class="tag is-rounded">&#8635; repeats</span>
      </div>
    </div>

    <div class="column">
      <EventTitle :event="event" />
      <div class="event-tags tags">
        <EventCategory :event="event" @set-category="setEventCategory" />
        <EventResource :event="event" @set-resource="setEventResource" />
        <EventActivity :event="event" :activity="activity" @set-activity="setEventActivity" />
        <span v-if="hasActivity" class="tag pull-right show-details" @click="toggleDetails">
          <span>{{ showDetails ? "Hide" : "Show" }} details</span>
        </span>
      </div>

      <div class="event-details columns" :class="{ collapsed: !showDetails }">
        <div class="column is-three-fifths">
          <TabBar :tabs="['Description']" />
          <EventDescription :description="description" />
        </div>

        <div class="column">
          <TabBar v-model="upperTab" :tabs="upperTabs" />

          <EventAttendees
            v-if="upperTab == 'Attendees'"
            :attendees="allAttendees"
            @add-contact="addContact"
          />

          <EventProperties
            v-if="upperTab == 'Properties'"
            :properties="eventProperties"
            @save="updateEvent"
          />
        </div>
      </div>

      <div v-if="hasActivity || needsActivity" class="event-activity-editor">
        <TabBar v-model="lowerTab" :tabs="hasTranscript ? lowerTabs : [lowerTabs[0]]" show-all />

        <EventActivityEditor
          v-if="lowerTab == 'Activity Notes' || lowerTab == 'all'"
          :event="event"
          :activity="activity"
          :note-written="noteWritten"
          @set-activity="setEventActivity"
        />

        <EventSummary
          v-if="lowerTab == 'Summary' || lowerTab == 'all'"
          :transcript-id="transcriptId"
        />

        <EventTranscript
          v-show="lowerTab == 'Transcript' || lowerTab == 'all'"
          :transcript-id="transcriptId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventActivity from "@/components/EventActivity.vue"
import EventActivityEditor from "@/components/EventActivityEditor.vue"
import EventCategory from "@/components/EventCategory.vue"
import EventDate from "@/components/EventDate.vue"
import EventDescription from "@/components/EventDescription.vue"
import EventAttendees from "@/components/EventAttendees.vue"
import EventProperties from "@/components/EventProperties.vue"
import EventResource from "@/components/EventResource.vue"
import EventSummary from "@/components/EventSummary.vue"
import EventTitle from "@/components/EventTitle.vue"
import EventTranscript from "@/components/EventTranscript.vue"
import TabBar from "@/components/TabBar.vue"
import googleCal from "@/common/google/calendar"
import { googleClient } from "@/common/google/client"

export default {
  components: {
    EventActivity,
    EventActivityEditor,
    EventCategory,
    EventDate,
    EventDescription,
    EventAttendees,
    EventProperties,
    EventResource,
    EventTitle,
    EventSummary,
    EventTranscript,
    TabBar,
  },
  props: {
    calendar: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      default: null,
    },
    transcriptId: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ["event-updated", "add-contact", "expand-event"],
  data() {
    return {
      showDetails: true,
      noteWritten: false,
      upperTab: "Attendees",
      upperTabs: ["Attendees", "Properties"],
      lowerTab: "Activity Notes",
      lowerTabs: ["Activity Notes", "Summary", "Transcript"],
      showModal: false,
    }
  },
  computed: {
    hasActivity() {
      return !!this.event?.extendedProperties?.private?.activityId
    },
    hasTranscript() {
      return !!this.transcriptId
    },
    needsActivity() {
      const categories = ["portfolio", "pipeline", "networking"]
      const properties = this.event?.extendedProperties?.private || {}
      const hasCategory = categories.includes(properties.eventCategory)
      const resourceId = properties.dealId || properties.organizationId || properties.personId
      return hasCategory && resourceId != null && !this.hasActivity
    },
    recurring() {
      return this.event.recurrence || this.event.recurringEventId
    },
    allAttendees() {
      return (this.event.attendees || []).filter((attendee) => !attendee.resource)
    },
    acceptedAttendees() {
      return this.allAttendees.filter((attendee) => attendee.responseStatus == "accepted")
    },
    attendeeCount() {
      return this.allAttendees.length == this.acceptedAttendees.length
        ? this.allAttendees.length
        : `${this.acceptedAttendees.length}/${this.allAttendees.length}`
    },
    description() {
      return this.event?.description || ""
    },
    eventProperties() {
      return this.event?.extendedProperties || {}
    },
  },
  watch: {
    hasActivity: {
      immediate: true,
      handler(hasActivity) {
        this.showDetails = !hasActivity
      },
    },
  },
  mounted() {
    if (this.hasActivity) {
      this.noteWritten = this.event.extendedProperties?.private?.note === "true"
    }
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    async fetchEvent(calendarId, eventId) {
      const client = await googleClient()
      if (!client) return // google not available

      return googleCal.getEvent(client, calendarId, eventId).then((resp) => resp.result)
    },
    setEventCategory: async function (category) {
      const extendedProperties = {
        private: {
          isSet: true,
          eventCategory: category,
          activityId: "",
        },
      }
      this._updateExtendedProperties(this.event.id, extendedProperties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent)
      })
      /* TODO: this is for recurring events, but it's not working
      const eventId = this.recurring ? this.event.recurringEventId : this.event.id;
      let updatedEvent = await this._updateExtendedProperties(eventId, extendedProperties);
      if (this.recurring) {
        // event returned from update is recurring parent event, so fetch current
        // state of this event
        updatedEvent = await this.fetchEvent(this.calendar, this.event.id);
        // parent/child updates are eventually consistent, timing may miss, ensure
        // extended props matches known state
        updatedEvent.extendedProperties = extendedProperties;
      } */
    },
    setEventResource: async function (resourceProperties) {
      const extendedProperties = {
        private: resourceProperties,
      }
      this._updateExtendedProperties(this.event.id, extendedProperties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent)
      })
    },
    setEventActivity(activityProperties) {
      if (Object.keys(activityProperties).includes("note")) {
        this.noteWritten = activityProperties.note
      }
      const extendedProperties = {
        private: activityProperties,
      }
      this._updateExtendedProperties(this.event.id, extendedProperties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent)
      })
    },
    addContact(person) {
      this.$emit("add-contact", person)
    },
    updateEvent(properties) {
      this._updateExtendedProperties(this.event.id, properties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent)
      })
    },
    async _updateExtendedProperties(eventId, extendedProperties) {
      const client = await googleClient()
      if (!client) return // google not available

      return googleCal
        .updateEvent(client, this.calendar, eventId, { extendedProperties })
        .then((response) => response.result)
        .catch((response) => {
          console.error(JSON.parse(response.body).error)
          let errors = JSON.parse(response.body).error.errors
          errors.map((e) => this.$toasted.error(e.message))
          throw errors
        })
    },
  },
}
</script>

<style lang="sass">
.calendar-event
  min-height: 20rem
  &:not(:last-child)
    border-bottom: 1px solid #e5e5e5
  .show-details
    opacity: 0
    transition: 0.2s 0s ease-out
  &:hover .show-details
    opacity: 1
    transition: 0.2s 0s ease-out
  .recurrence
    text-align: center
  .event-tags
    margin: 0.5rem 0
    .tag
      cursor: pointer
      max-width: 18rem
      &.pull-right
        margin-left: auto
      span, a
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    .autocomplete
      margin-top: -0.45rem
  .event-details
    opacity: 1
    max-height: 50rem
    transition: 0.2s 0s ease-out
    padding-bottom: 0.5rem
    &.collapsed
      transition: 0.2s 0s ease-out
      max-height: 0
      opacity: 0
      overflow: hidden
  .tabs.is-small
    margin-top: 0.5rem
    margin-bottom: 0.8rem
    li a
      padding: 0.1rem 0.4rem 0.1rem 0.4rem
</style>
