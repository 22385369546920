// base urls
const webBaseUrl = "https://app.carta.com/investors/beta/"

const organizationWebUrl = (id) => {
  return webBaseUrl + "firm/2814/portfolio/investment/" + (id || "")
}

const fundWebUrl = (id) => {
  return webBaseUrl + "fund/" + id
}

export const web = {
  baseUrl: webBaseUrl,
  organizationUrl: organizationWebUrl,
  fundUrl: fundWebUrl,
}

export default {
  web,
}
