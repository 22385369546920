<template>
  <div class="event-transcript is-size-7">
    <ul class="speaker-tags tags">
      <li v-for="(s, i) in speakers" :key="i" :class="tagClass(s)" @click="toggleSpeaker(s)">
        {{ s }}
      </li>
      <li style="margin: 0 0 0.5rem auto">
        <div class="control has-icons-left has-icons-right">
          <input
            v-model="filterText"
            type="text"
            class="input is-small"
            placeholder="Search transcript"
          />
          <span class="icon is-small is-left">
            <font-awesome-icon icon="search" />
          </span>
          <span class="icon is-small is-right">
            <a class="delete is-small" @click="filterText = ''"></a>
          </span>
        </div>
      </li>
    </ul>

    <div class="is-flex is-flex-direction-column" style="overflow: hidden">
      <audio controls :src="audioUrl">Your browser does not support the audio element.</audio>
      <ul class="conversation">
        <li
          v-for="(segment, index) in filteredConversation"
          :key="index"
          class="segment"
          :class="speakerClass(segment)"
          @click="jumpTo(segment.start)"
        >
          <p class="speech">
            <span v-for="(line, i) in segment.lines" :key="i" class="sentence" v-html="line"></span>
          </p>
          <p class="speaker">{{ segment.speaker }} ({{ secondsToTimestamp(segment.start) }})</p>
        </li>
      </ul>

      <!-- <ul>
      <li v-for="(line, index) in transcript.sentences" :key="index" class="sentence">
        <span>{{  secondsToTimestamp(line.start_time) }}</span><span>{{ line.text }}</span>
      </li>
    </ul> -->
    </div>
  </div>
</template>

<script>
import { FIREFLIES_USERS, TRANSCRIPT_BY_ID } from "@/graphql/fireflies"

export default {
  components: {},
  props: {
    transcriptId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      transcript: null,
      selectedSpeakers: [],
      heavybitSpeakers: [],
      filterText: "",
    }
  },
  apollo: {
    $client: "firefliesClient",
    transcript: {
      skip() {
        return !this.transcriptId // only fetch transcript if transcriptId is provided
      },
      query: TRANSCRIPT_BY_ID,
      variables() {
        return {
          id: this.transcriptId,
        }
      },
    },
    heavybitSpeakers: {
      skip() {
        return !this.transcript
      },
      query: FIREFLIES_USERS,
    },
  },
  computed: {
    audioUrl() {
      return this.transcript?.audio_url || ""
    },
    conversation() {
      // The transcript is just a list of sentences with timestamps. A conversation
      // is the back and forth between speakers. This function groups consecutive
      // sentences by speaker and returns a list of with the start and end time of each
      // back and forth:
      //
      // [
      //   { speaker: "Alice", start: 0, end: 10, lines: [ "Hi, my name is Alice", "Nice to meet you" ] }
      //   { speaker: "Bob", start: 10, end: 20, lines: [ "Hi Alice, I'm Bob", "Nice to meet you too" ] }
      // ]

      let conversation = []
      let currentSpeaker = null
      let currentText = []
      let currentStart = 0
      let heavybitSpeakers = this.heavybitSpeakers.map((user) => user.name)

      this.transcript?.sentences?.forEach((sentence) => {
        // If the sentence is from the same speaker, add it to the current text
        // Strip any brackets and contents from speaker name e.g. (he/him)
        let speaker = (sentence.speaker_name || "Unknown Speaker").replace(/\(.*\)/, "").trim()

        if (speaker === currentSpeaker) {
          let html = this.highlightText(sentence.text, this.filterText)
          currentText.push(html)
          return
        }

        // If the sentence is from a different speaker, add the current text to the conversation
        // and start a new text for the new speaker
        if (currentText.length > 0) {
          conversation.push({
            speaker: currentSpeaker,
            isHeavybit: heavybitSpeakers.includes(currentSpeaker),
            start: currentStart,
            end: sentence.start_time,
            lines: currentText,
          })
        }

        let html = this.highlightText(sentence.text, this.filterText)
        currentSpeaker = speaker
        currentStart = sentence.start_time
        currentText = [html]
      })

      return conversation
    },
    filteredConversation() {
      // Filter the conversation by the selected speakers if any
      const isFiltered = this.selectedSpeakers.length < this.speakers.length
      const speakerFilter = (item) => this.selectedSpeakers.includes(item.speaker)
      const conversation = isFiltered ? this.conversation.filter(speakerFilter) : this.conversation

      // Filter the conversation by the text in the search box
      if (this.filterText.length < 3) {
        return conversation
      }

      // It's useful to see some context around the search term so we
      // find the index conversation fragments that contain the search term
      // and return the previous and next fragment as well
      let fragments = []
      let lastFragment = null
      let containsSearchTerm = (lines) => {
        return lines.some((line) => {
          return line.toLowerCase().includes(this.filterText.toLowerCase())
        })
      }

      conversation?.forEach((thisFragment) => {
        if (containsSearchTerm(thisFragment.lines)) {
          fragments.push(thisFragment)
          lastFragment = thisFragment
          return
        }

        if (lastFragment) {
          fragments.push(thisFragment)
          lastFragment = null
        }
      })

      return fragments
    },
    speakers() {
      // Ridiculously Fireflies' own API returns duplicate speaker names
      // so we need to do this manually. We can also sort so Heavybit ppl
      // come first.

      // Iterate over each sentence and extract the speaker
      let speakers = new Set()
      this.transcript?.sentences?.forEach((sentence) => {
        // Strip brackets and contents from speaker name
        speakers.add((sentence.speaker_name || "Unknown Speaker").replace(/\(.*\)/, "").trim())
      })

      // Sort the speakers so Heavybit speakers come first
      let hb = this.heavybitSpeakers.map((user) => user.name)
      let sortHeavybit = (a, b) => {
        return hb.includes(a) !== hb.includes(b) ? (hb.includes(a) ? -1 : 1) : a.localeCompare(b)
      }

      return [...speakers].sort(sortHeavybit)
    },
  },
  watch: {
    speakers() {
      // When the speakers change, reset the selected speakers to all
      this.selectedSpeakers = this.speakers
    },
  },
  methods: {
    tagClass(speaker) {
      let isSelected = this.selectedSpeakers.includes(speaker)
      let speakerNumber = this.speakers.indexOf(speaker) + 1
      let isHeavybit = this.heavybitSpeakers.map((user) => user.name).includes(speaker)
      return {
        tag: true,
        "is-heavybit": isHeavybit,
        [`speaker-${speakerNumber}`]: true,
        "is-disabled": !isSelected,
      }
    },
    speakerClass(segment) {
      let speakerNumber = this.speakers.indexOf(segment.speaker) + 1
      return {
        "is-heavybit": segment.isHeavybit,
        [`speaker-${speakerNumber}`]: true,
      }
    },
    toggleSpeaker(speaker) {
      // Turn speakers on and off
      if (this.selectedSpeakers.includes(speaker)) {
        this.selectedSpeakers = this.selectedSpeakers.filter((s) => s !== speaker)
      } else {
        this.selectedSpeakers.push(speaker)
      }
    },
    jumpTo(seconds) {
      // Jump to a specific time in the audio and start playing
      let audio = this.$el.querySelector("audio")
      audio.currentTime = seconds
      audio.play()
    },
    secondsToTimestamp(seconds) {
      // Force seconds to be a 5 character timestamp 00:00
      let minutes = Math.floor(seconds / 60)
      let remainingSeconds = Math.floor(seconds % 60)
      return `${minutes < 10 ? "0" : ""}${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`
    },
    highlightText(text, substring) {
      // Refuse to highlight if the substring is less than 3 characters
      if (substring.length < 3) {
        return text
      }

      // Highlight the substring in the text
      var index = text.toLowerCase().indexOf(substring.toLowerCase())
      if (index >= 0) {
        const prefix = text.substring(0, index)
        const highlight = text.substring(index, index + substring.length)
        const postfix = text.substring(index + substring.length)

        return `${prefix}<span class='highlight'>${highlight}</span>${postfix}`
      }
      return text
    },
  },
}
</script>

<style lang="sass">
.event-transcript
  audio
    border: 1px solid #e0e0e0
    border-bottom: none
    box-shadow: 0 2px 15px rgba(10, 10, 10, 0.1)
    width: 100%
    &::-webkit-media-controls-panel
      background: white
  .speaker-tags
    padding: 0.5rem 0rem 0rem .4rem
    .tag
      cursor: pointer
      &.is-disabled
        pointer-events: all // Keep Bulma's style but allow pointer events
    .speaker-1
      background-color: cornflowerblue
      color: white
    .speaker-2
      background-color: #63a773
      color: white
    .speaker-3
      background-color: #f7b267
      color: white
    .speaker-4
      background-color: #f76c6c
      color: white
  .conversation
    border: 1px solid #ddd
    display: flex
    flex-direction: column
    gap: 0.7rem
    padding: 1rem
    height: 20rem
    overflow-y: scroll
  .segment
    max-width: 80%
    align-self: flex-end
    &.is-heavybit
      align-self: flex-start
    &.speaker-1
      p.speech
        border-radius: 0.75rem 0.4rem
        background-color: cornflowerblue
        color: white
    &.speaker-2 p.speech,
    .tag.speaker-2
      background-color: #63a773
      color: white
    &.speaker-3
      p.speech
        background-color: #f7b267
        color: white
    &.speaker-4
      p.speech
        background-color: #f76c6c
        color: white
    p.speaker
      color: #888
      padding: 0 0 0 0.55rem
      margin-top: 0.25rem
    p.speech
      // push the p to the right in the parent
      cursor: pointer
      font-size: 0.9rem
      padding: 0.35rem 0.55rem
      border-radius: 0.4rem 0.75rem
      span:not(:last-child)
        margin-right: 0.3rem
</style>
