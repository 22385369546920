<template>
  <div ref="tooltip" class="measurement-tooltip">
    <span class="tip">{{ text }}</span>
    <span class="down-triangle"></span>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      default: () => {},
    },
    text: {
      type: String,
      default: "",
    },
  },
  watch: {
    position(newVal) {
      // convert position to active css rules
      this.$refs.tooltip.style.top = newVal.top + "px"
      this.$refs.tooltip.style.left = newVal.left + "px"
    },
  },
}
</script>

<style lang="sass" scoped>
.measurement-tooltip
  position: absolute
.down-triangle
  border-color: rgba(74, 74, 74, 0.9) transparent transparent transparent
  border-style: solid
  border-width: 0.5rem
  bottom: auto
  content: ''
  left: 50%
  margin-left: -0.5rem
  margin-top: -0.5rem
  pointer-events: none
  position: absolute
  top: 0
  z-index: 9999
.tip
  background: rgba(74, 74, 74, 0.9)
  border-radius: 4px
  bottom: 100%
  color: #fff
  font-size: 0.75rem
  left: 50%
  max-width: 24rem
  padding: 0.4rem 0.8rem
  pointer-events: none
  position: absolute
  text-overflow: ellipsis
  transform: translate(-50%, -0.5rem)
  white-space: nowrap
  z-index: 9999
</style>
