<template>
  <div class="event-summary" v-html="cleanSummary"></div>
</template>

<script>
import { TRANSCRIPT_BY_ID } from "@/graphql/fireflies"

export default {
  components: {},
  props: {
    transcriptId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      transcript: null,
      loading: false,
    }
  },
  apollo: {
    $client: "firefliesClient",
    transcript: {
      skip() {
        return !this.transcriptId // only fetch transcript if transcriptId is provided
      },
      query: TRANSCRIPT_BY_ID,
      loadingKey: "loading",
      variables() {
        return {
          id: this.transcriptId,
        }
      },
    },
  },
  computed: {
    hasTranscript() {
      return this.transcript?.summary
    },
    cleanSummary() {
      let content = this.transcript?.summary?.shorthand_bullet || ""
      let lines = content.split("\n")
      // Iterate over each line, if it starts with an emoji, it's the start of a new bullet
      // and the lines after it are sub-bullets (until the next line that starts with an emoji)
      let html = "<ul>"
      let indent = 0
      for (let i = 0; i < lines.length; i++) {
        // Skip empty lines
        if (lines[i].trim() === "") {
          continue
        }

        // Convert markdown ** to html
        let line = lines[i].replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        let hasEmoji = line.match(/^\p{Emoji}/u)
        if (hasEmoji) {
          if (indent > 0) {
            indent = 0
            html += "</li></ul>" // Close the previous bullet
          }

          html += "<li><p>" + line + "</p><ul>" // Start a new bullet
          indent = 1
        } else {
          html += "<li>" + line + "</li>"
        }
      }
      html += "</ul>"
      return html
    },
  },
  methods: {},
}
</script>

<style lang="sass">
.event-summary
  border: 1px solid #e0e0e0
  padding: 0.6rem 0.7rem
  font-size: 0.8rem
  min-height: 10rem
  ul strong
    padding-left: 0.25rem
  ul ul
    list-style: circle
    padding-left: 2rem
</style>
