export const commafy = (value) => {
  return Number(value).toLocaleString()
}

// return a formatted currency string rounding off cents
export const currencyDollars = (value) => {
  if (parseFloat(value) != value) return value
  return currencyFull(Math.round(value))
}

export const currencyFull = (value) => {
  if (parseFloat(value) != value) return value

  const [dollars, cents] = value.toString().split(".")
  const chunks = dollars.match(/.{1,3}(?=(.{3})*$)/g)
  let formatted = "$" + chunks.join(",")
  if (cents) {
    formatted = formatted + "." + cents
  }
  return formatted
}

export const currencyShort = (value) => {
  if (typeof value !== "number" || isNaN(value)) {
    return value
  }

  const [dollars] = value.toString().split(".")
  const chunks = dollars.match(/.{1,3}(?=(.{3})*$)/g)
  let formatted = ""

  if (chunks.length == 4) {
    formatted = _buildSummaryNumber(chunks) + "B"
  } else if (chunks.length == 3) {
    formatted = _buildSummaryNumber(chunks) + "M"
  } else if (chunks.length == 2) {
    formatted = Math.round(value / 1000.0) + "k"
  } else if (chunks.length == 1) {
    formatted = Math.round(value)
  } else {
    formatted = chunks.join(",")
  }
  return "$" + formatted
}

const _buildSummaryNumber = (chunks) => {
  let fragment
  // round from three to two digits
  let partial = Math.round(chunks[1] / 10.0)
  let summary = chunks[0]
  if (partial == 100) {
    // partial rounds to a full summary digit
    summary = (Number(summary) + 1).toString()
  } else if (partial != 0) {
    let partialString = partial.toString()
    if (partialString.length === 1) {
      // add back leading zero
      partialString = "0" + partialString
    }
    // trim trailing zeros
    fragment = partialString[1] === "0" ? partialString[0] : partialString
    summary += "." + fragment
  }
  return summary
}

export default {
  commafy,
  currencyDollars,
  currencyFull,
  currencyShort,
}
