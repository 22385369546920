<template>
  <section class="fund-investors section">
    <div class="toolbar">
      <h2 class="title">Commitments</h2>
      <div class="tools">
        <div class="field">
          <label class="field-label is-small has-text-weight-semibold">Group By:</label>
          <div class="select is-small">
            <select v-model="groupBy" selected="none">
              <option v-for="option in groupOptions" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <button class="button is-small" @click="download">Download</button>
      </div>
    </div>

    <table id="commitments" class="table is-fullwidth">
      <thead>
        <tr>
          <th v-if="isGrouped" class="group">{{ $filters.capitalize(groupBy) }}</th>
          <th class="commitment-title">Description</th>
          <th class="commitment-title">Contact</th>
          <th class="fixed-width">Period</th>
          <th class="fixed-width">Value</th>
        </tr>
      </thead>

      <tbody v-if="!commitments || commitments.length == 0">
        <tr>
          <td colspan="7" class="no-commitments">No commitments found</td>
        </tr>
      </tbody>

      <tbody v-for="group in groups" v-else-if="isGrouped" :key="group.id">
        <tr v-for="(commitment, index) in group.commitments" :key="commitment.id">
          <td v-if="index == 0" :rowspan="group.commitments.length" class="group">
            {{ group.name }}
            <p class="is-size-7 has-text-grey-light has-text-weight-normal">
              ${{ (group.totalValue / 1e6).toFixed(1) }}m,
              {{ $filters.percentage((group.totalValue / totalValue()) * 100) }}
              <br />
              {{ group.commitments.length }} commits
            </p>
          </td>

          <td class="commitment-title">
            <a target="_blank" class="has-text-dark" :href="dealUrl(commitment.id)">
              {{ commitment.title }}
            </a>
            <p v-if="commitment.org_id" class="is-size-7">
              <router-link
                :to="{ name: 'company-show', params: { id: commitment.org_id.value } }"
                class="has-text-grey"
              >
                {{ commitment.org_id.name }}
              </router-link>
            </p>
          </td>
          <td class="commitment-title">
            <i v-if="!commitment.person_id">Deal person unassigned</i>
            <a v-else target="_blank" class="subtle-link" :href="personUrl(commitment)">
              {{ commitment.person_id ? commitment.person_id.name : "" }}
            </a>
          </td>
          <td>{{ $filters.quarter(commitment.won_time) }}</td>
          <td>{{ $filters.currencyRounded(commitment.value) }}</td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr v-for="commitment in commitments" :key="commitment.id">
          <td class="commitment-title">
            <a
              target="_blank"
              class="has-text-dark has-text-weight-semibold"
              :href="dealUrl(commitment.id)"
              >{{ commitment.title }}</a
            >
            <p v-if="commitment.org_id" class="is-size-7">
              <router-link
                :to="{ name: 'company-show', params: { id: commitment.org_id.value } }"
                class="has-text-grey"
              >
                {{ commitment.org_id.name }}
              </router-link>
            </p>
          </td>
          <td class="commitment-title">
            {{ commitment.person_id ? commitment.person_id.name : "" }}
          </td>
          <td>{{ $filters.quarter(commitment.won_time) }}</td>
          <td>{{ $filters.currencyRounded(commitment.value) }}</td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th v-if="isGrouped" class="group"></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ $filters.currencyRounded(totalValue()) }}</th>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import { startOfQuarter, addMonths, format as dateFormat, isSameQuarter } from "date-fns"
import { toDate } from "@/utils/date"
import { fetchCommitmentTypes } from "@/common/deals"
import pipedrive from "@/common/pipedrive"
import { downloadCSVFromTable } from "@/utils/csv"

export default {
  props: {
    commitments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allTypes: {},
      groupOptions: [
        { value: "", text: "None", groups: () => [] },
        { value: "quarter", text: "Quarter", groups: this.groupsByQuarter },
        { value: "type", text: "Type", groups: this.groupsByType },
      ],
      groupBy: "type",
    }
  },
  computed: {
    investors() {
      if (!this.commitments || !this.commitments.length) return []

      // Get a list of lps from each commitment
      var lps = this.commitments.map(function (deal) {
        var lp = deal["org_id"]

        if (lp) return { id: lp.value, name: lp.name }
        return { id: -1, name: "Unassigned" }
      })

      // Remove duplicates
      lps = lps.filter(function (value, index, self) {
        return index === self.findIndex((item) => item.id == value.id)
      })

      return lps
    },
    quarters() {
      if (this.commitments == undefined || this.commitments.length === 0) return []

      // Get the first and last dates of the commitments
      // N.B. this requires that the commitments are pre-sorted by date
      // which is done by the query options in the FundShow components
      let firstDate = new Date(this.commitments[0].won_time)
      let lastDate = firstDate
      this.commitments.map(function (investment) {
        const date = new Date(investment.won_time)
        lastDate = date > lastDate ? date : lastDate
        firstDate = date < firstDate ? date : firstDate
      })

      // Construct an array of quarters from first to last date
      let start = startOfQuarter(firstDate)
      const quarters = []
      while (start < lastDate) {
        quarters.push({
          name: dateFormat(start, "qqq yyyy"),
          start: start,
        })
        start = addMonths(start, 3)
      }
      return quarters
    },
    isGrouped() {
      return this.groupBy && this.groups.length > 0
    },
    groups() {
      const option = this.groupOptions.find((g) => g.value == this.groupBy)
      return option.groups.call()
    },
  },
  created() {
    this.fetchFields()
  },
  methods: {
    fetchFields() {
      fetchCommitmentTypes().then((commitTypes) => (this.allTypes = commitTypes))
    },
    groupsByQuarter() {
      if (!this.commitments || this.commitments.length == 0) return []
      const deals = this.commitments || []
      const quarters = this.quarters.reverse()

      return quarters.map((q) => {
        const commitments = deals.filter((d) => isSameQuarter(toDate(d.won_time), toDate(q.start)))
        return {
          id: q.name,
          name: q.name,
          commitments,
          totalValue: this.totalValue(commitments),
        }
      })
    },
    groupsByType() {
      if (!this.commitments || this.commitments.length == 0) return []
      const typeKey = "50982245d6002b8e51241bb34446265876812b38"
      const groups = Object.keys(this.allTypes).map((typeId) => {
        const commitments = this.commitments.filter((c) => c[typeKey] == typeId)
        return {
          id: typeId,
          name: this.allTypes[typeId],
          commitments,
          totalValue: this.totalValue(commitments),
        }
      })
      return groups.filter((g) => g.commitments.length > 0) // Only show non-empty groups
    },
    totalValue(commitments) {
      return (commitments || this.commitments).reduce((sum, c) => (sum += c.value), 0)
    },
    dealUrl(id) {
      return pipedrive.web.dealUrl(id)
    },
    personUrl(commitment) {
      if (!commitment.person_id) return null
      return pipedrive.web.personUrl(commitment.person_id.value)
    },
    vehicle(commitment) {
      const key = "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2"
      return commitment[key] ? commitment[key].name : ""
    },
    download() {
      downloadCSVFromTable("commitments")
    },
  },
}
</script>

<style lang="sass" scoped>
#commitments
  margin-top: 1rem
  font-size: 0.9rem
  .fixed-width
    width: 8rem
  td, th
    text-align: right
    padding-top: 0.3rem
    padding-bottom: 0.3rem
    padding-left:0
    height: 3rem
    vertical-align: top
  thead th
    vertical-align: bottom
    height: unset
  td.group,
  th.group
    text-align: left
    width: 12rem
  td.group
    font-weight: 600
    vertical-align: top
  th.commitment-title,
  td.commitment-title
    text-align: left
  td.no-commitments
    font-weight: 600
    padding-top: 0.9rem
    padding-bottom: 0.9rem
    text-align: center
    color: lightgray
</style>
