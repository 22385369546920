<template>
  <header>
    <NavBar />
    <div
      v-if="authorizationNeeded"
      id="google-auth"
      class="has-background-warning p-2 has-text-centered"
    >
      Google authorization is needed for Auger. Please
      <a @click="startGoogleAuthorization">authorize now</a>.
    </div>
  </header>
</template>

<script>
import { mapState } from "pinia"
import NavBar from "@/components/NavBar.vue"
import { startGoogleAuthorization } from "@/common/google/authorization"
import { useGoogleStore } from "@/stores/google"

export default {
  components: {
    NavBar,
  },
  computed: {
    ...mapState(useGoogleStore, ["authorizationNeeded"]),
  },
  methods: {
    startGoogleAuthorization,
  },
}
</script>

<style lang="sass">
#app > header
  background-color: var(--bulma-dark)
</style>
