import { dealFields } from "./pipedrive/resources"

// Map object of all current deal type ids -> labels
export const fetchDealTypes = () => _dealFieldTypeMap("12454")

// Map object of all current commitment type ids -> labels
export const fetchCommitmentTypes = () => _dealFieldTypeMap("12521")

// Map object of all current transaction type (primary vs. secondary)
export const fetchTransactionTypes = () => _dealFieldTypeMap("12522")

const _dealFieldTypeMap = async (fieldId) => {
  const types = await dealFields.details(fieldId)
  return types.options.reduce((hash, value) => {
    hash[value.id] = value.label
    return hash
  }, {})
}

export default {
  fetchDealTypes,
  fetchCommitmentTypes,
  fetchTransactionTypes,
}
