// dates are a mess in Javascript, let's make them better
// helpful resource: https://css-tricks.com/everything-you-need-to-know-about-date-in-javascript/

import { isNumeric } from "@/utils/number"

const regexps = {
  dateString: /^\d{4}-\d{1,2}-\d{1,2}$/,
  isoString: /^\d{4}-\d{1,2}-\d{1,2}T\d{2}:\d{2}:\d{2}/,
  millisecondEpoch: /^\d{12,13}$/,
  pipedriveTime: /^\d{4}-\d{1,2}-\d{1,2}\s\d{2}:\d{2}:\d{2}$/,
}

// take a YYYY-MM-DD string and return an object with day, month, year properties
export const dateStringInChunks = (dateStr) => {
  const [year, month, day] = dateStr.split("-")
  return { year, month, day }
}

// get an array of the years included in a provided set of dates
export const extractYears = (dates) => {
  const years = []
  dates.forEach((date) => {
    if (!isDate(date)) throw "recieved a non-date object!"
    years.push(date.getFullYear())
  })
  return [...new Set(years)].sort()
}

export const isDate = (obj) => {
  return obj instanceof Date
}

export const isDateString = (obj) => {
  return regexps.dateString.test(obj)
}

// do two dates or datestrings occur in the same calendar day?
export const isSameDay = (first, second) => {
  const sFirst = sanitizeTime(first)
  const sSecond = sanitizeTime(second)
  return sFirst.valueOf() === sSecond.valueOf()
}

// do two dates or datestrings occur at the exact same time?
// comparing two js dates with the same epoch time with === will be false
// because they are not the same object - use this instead.
export const isSameTime = (first, second) => {
  const firstTime = toDate(first).getTime()
  const secondTime = toDate(second).getTime()
  return firstTime === secondTime
}

// generates a date string X months ago from today
export const monthsAgo = (count) => {
  const date = new Date() // now
  date.setMonth(date.getMonth() - count)
  return toDateString(date)
}

// create a js date representing the current time
export const now = () => {
  return new Date()
}

// take a date obj or YYYY-MM-DD datestring and return a date obj
// of midnight UTC of that date; assumes date objects are in local timezone
export const sanitizeTime = (date) => {
  if (isDate(date)) {
    let toUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return toUTC
  } else if (regexps.dateString.test(date)) {
    return new Date(`${date}T00:00:00Z`)
  } else {
    throw "Date is an unexpected type"
  }
}

// universal converter across our common formats to a local-time js date object. currently
// accepts js dates, YYYY-MM-DD datestrings, ISO timestrings and pipedrive timestrings.
export const toDate = (dateOrDateString) => {
  const input = dateOrDateString
  if (isDate(input)) return input
  // we are dealing with some kind of date string, assume UTC if no TZ is specified
  if (regexps.dateString.test(input)) {
    return _dateStringToDate(input)
  } else if (regexps.isoString.test(input)) {
    return new Date(input) // date can parse these
  } else if (regexps.pipedriveTime.test(input)) {
    return _pipedriveTimeToDate(input)
  } else if (isNumeric(input) && regexps.millisecondEpoch.test(input)) {
    return new Date(input) // date can parse these
  } else {
    throw new Error("Unknown date format")
  }
}

// convert a js date to a YYYY-MM-DD datestring (local timezone)
export const toDateString = (dateOrDateString) => {
  if (regexps.dateString.test(dateOrDateString)) return dateOrDateString
  const date = toDate(dateOrDateString)
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")
  return date.getUTCFullYear() + "-" + month + "-" + day
}

// convert a js date to a YYYY-MM-DD datestring (UTC)
export const toUTCDateString = (dateOrDateString) => {
  const date = toDate(dateOrDateString)
  return date.toISOString().split("T")[0]
}

// YYYY-MM-DD date string for the current date in local timezone
export const today = () => {
  return toDateString(new Date())
}

const _dateStringToDate = (dateString) => {
  const chunks = dateStringInChunks(dateString)
  return new Date(chunks.year, chunks.month - 1, chunks.day)
}

const _pipedriveTimeToDate = (pipedriveTime) => {
  const [dateString, timeString] = pipedriveTime.split(" ")
  return new Date(dateString + "T" + timeString + "Z")
}

export default {
  dateStringInChunks,
  extractYears,
  isDate,
  isSameDay,
  isSameTime,
  monthsAgo,
  now,
  sanitizeTime,
  toDate,
  toDateString,
  today,
}
