<template>
  <div class="select-header">
    <ul v-show="expanded" :style="listStyle">
      <li v-for="(_option, index) in options" :key="index" @mouseup="selectOption(index)">
        <h1 class="is-size-2" :class="{ selected: selectedIndex == index }">{{ titles[index] }}</h1>
      </li>
    </ul>
    <h1 ref="header" class="is-size-2" :style="headerStyle" @mousedown.prevent="showOptions">
      {{ selectedTitle }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    valueKey: {
      type: String,
      required: true,
    },
    titleKey: {
      type: String,
      required: true,
    },
    selected: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },
  emits: ["select"],
  data() {
    return {
      selectedIndex: 1,
      expanded: false,
      // height: 0,
    }
  },
  computed: {
    values() {
      return this.options.map((option) => option[this.valueKey])
    },
    titles() {
      return this.options.map((option) => option[this.titleKey])
    },
    headerStyle() {
      return {
        color: this.expanded ? "transparent" : "inherit",
      }
    },
    listStyle() {
      const listPadding = 10 // From .select-header ul padding style below
      return { top: -listPadding + "px" }
      // The following approach centers the list on the currently selected item
      // This is disabled because currently it doesn't gracefully handle the case
      //  where the top of the list is above the top of the page.
      // const top = -listPadding + this.selectedIndex * this.height * -1;
      // return { top: top + "px" };
    },
    selectedTitle() {
      const index = this.values.indexOf(this.selected) || this.selectedIndex
      const option = this.options[index]
      const title = option[this.titleKey || this.valueKey]
      return title
    },
  },
  mounted() {
    // this.height = this.$refs.header.clientHeight
    this.selectedIndex = Math.max(0, this.values.indexOf(this.selected)) // Default to 0 if not found
  },
  methods: {
    isSelected(index) {
      return this.selectedIndex == index
    },
    showOptions() {
      window.addEventListener("mouseup", () => (this.expanded = false), { once: true })
      this.expanded = true
    },
    selectOption(index) {
      this.expanded = false
      this.selectedIndex = index
      const option = this.options[index][this.valueKey]
      this.$emit("select", option)
    },
  },
}
</script>

<style lang="sass">
.select-header
  position: relative
  padding-right: 2rem
.select-header > h1
  border: 1px solid transparent
  &:hover
    border-bottom-color: orange
.select-header h1
  user-select: none
  cursor: pointer
  margin-top: -1px    // Adjust for border so list header lines up with regular headers
  margin-left: -1px    // Adjust for border so list header lines up with regular headers
.select-header ul
  overflow: scroll
  margin:0
  padding: 10px 0
  left:-1.5rem
  border: 1px solid lightgray
  border-radius: 3px
  box-shadow: 0px 1px 4px lightgray
  position: absolute
  background: white
  z-index: 10000
  white-space: nowrap
  li
    padding: 0 1.5rem
    &:hover
     background: whitesmoke
  li:hover h1,
  li h1.selected
    color: inherit !important
</style>
