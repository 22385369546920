import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core"
import { setContext } from "@apollo/client/link/context"
import { createApolloProvider } from "@vue/apollo-option"
import { bearerHeader, firefliesHeader } from "@/common/authorization"
import config from "@/common/config"

let headers = {}
// ignore authentication and force a specific user
if (config.auth.disabled && config.api.adminSecret && config.general.forcedUser) {
  headers["x-hasura-admin-secret"] = config.api.adminSecret
  headers["x-hasura-user-id"] = config.general.forcedUser
  headers["x-hasura-role"] = "user"
}

const cache = new InMemoryCache()

const hasuraLink = createHttpLink({
  uri: config.api.url, // must be absolute URL
})

// send JWT authorization we get from Auth0 to Hasura
const hasuraContext = setContext((_, { headers }) => {
  return { headers: { ...headers, authorization: bearerHeader() } }
})

export const hasuraClient = new ApolloClient({
  link: hasuraContext.concat(hasuraLink),
  cache: cache,
  connectToDevTools: import.meta.env.NODE_ENV === "development",
})

const firefliesLink = createHttpLink({
  uri: "https://api.fireflies.ai/graphql",
})

const firefliesContext = setContext((_, { headers }) => {
  return { headers: { ...headers, authorization: firefliesHeader() } }
})

const firefliesClient = new ApolloClient({
  link: firefliesContext.concat(firefliesLink),
  cache: cache,
  connectToDevTools: import.meta.env.NODE_ENV === "development",
})

export const apolloProvider = createApolloProvider({
  defaultClient: hasuraClient,
  clients: {
    hasuraClient,
    firefliesClient,
  },
})
