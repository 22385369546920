<template>
  <a
    :href="href"
    :class="{ button: !inline, 'is-inline': inline, 'is-small': small, 'is-disabled': disabled }"
    target="_blank"
    class="to-carta"
    title="View on Carta"
  >
    <span class="icon">
      <img src="@/assets/images/carta_logo.svg" />
    </span>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: "https://app.carta.com/investors/beta/firm/2814/portfolio/all-investments/",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
a.to-carta.is-inline
  border: none
  display: inline-block !important
  opacity: 0.7 !important
  position: relative
  top: 5px
  &:hover
    opacity: 1.0 !important
a.to-carta.is-small.is-inline
  top: 2px
  img
    width: 1rem
</style>
