import { toDate } from "@/utils/date"

// generate pipedrive attendees list in the format pipedrive wants from
// a gcal event. If primaryId, a pipedrive ID, is specified, that user
// will be marked primary. Otherwise the first non-heavybit attendee on
// the event will be primary.
export const participants = function (attendees, primaryId = undefined) {
  if (!attendees || !attendees.length) return []
  // filter out conference rooms and unknown contacts
  const people = attendees.filter((a) => a.pipedriveId && !a.resource)
  // remove duplicates since sometimes more than one email is invited for same person
  const pipedriveIds = new Set(people.map((p) => p.pipedriveId))
  const participants = [...pipedriveIds].map((id) => ({ person_id: id, primary_flag: false }))
  // find primary contact
  let primaryIndex = -1
  if (primaryId) {
    // if primary has been specified, identify them
    primaryIndex = participants.findIndex((a) => a.person_id === primaryId)
  } else {
    // otherwise use first non-heavybit contact
    const first = people.find((p) => p.email.slice(p.email.length - 13) != "@heavybit.com")
    if (first) {
      primaryIndex = participants.findIndex((a) => a.person_id === first.pipedriveId)
    }
  }
  // assign primary if we have one
  if (primaryIndex >= 0) {
    participants[primaryIndex].primary_flag = true
  }
  return participants
}

// calculate event duration in the format that pipedrive wants, HH:MM
export const duration = function (start, end) {
  if (!start || !end) return null
  const duration = toDate(end.dateTime) - toDate(start.dateTime)
  let minutes = parseInt(duration / (1000 * 60)) // convert from ms
  const hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0")
}

// build the params list for creating a new activity from a gcal event
//
// https://developers.pipedrive.com/docs/api/v1/#!/ActivityFields
// required opts: subject, type; optional: deal_id, org_id, note, etc.
export const buildParams = function (subject, type, calEvent, opts = {}) {
  const start = toDate(calEvent.start.dateTime)
  const now = new Date()
  const [date, time] = start.toISOString().split("T")
  // if a person contact is specified, ensure they are primary contact
  const primaryId = opts.person_id
  let params = {
    subject,
    type,
    // API currently requires the boolean for done to be expressed as a 1 or 0
    // https://developers.pipedrive.com/docs/api/v1/Activities#getActivity
    done: start <= now ? 1 : 0,
    due_date: date, // YYYY-MM-DD
    due_time: time.substr(0, 5), // HH:MM
    duration: duration(calEvent.start, calEvent.end),
    location: calEvent.location,
    participants: participants(calEvent?.attendees, primaryId),
    // gcal_event_id: calEvent.id,
    // google_calendar_etag: calEvent.etag,
    // user_id: 811001 // heavybit team user is default owner
  }
  Object.assign(params, opts)
  return params
}

export default {
  buildParams,
  duration,
  participants,
}
