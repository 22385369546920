// Logic related to initializing google's two client libraries:
//   1. google client API (GAPI): mounts at window.gapi
//   2. google identity services (GIS): mounts at window.google.accounts
// GIS must be loaded after GAPI.

import { reactive } from "vue"
import { useGoogleStore } from "@/stores/google"
import { fetchGooglePublicKey, initializeSignInClient } from "./signin"
import { fetchAccessToken } from "./token"

// reactive state for our interactions while loading
const state = reactive({
  // are we actively trying to load a library?
  loadingGAPI: false,
  loadingGIS: false,
  // is the library loaded already?
  loadedGAPI: false, // is google API client library (GAPI) available?
  loadedGIS: false, // is google identity services (GIS) available?
})

const initalizeGoogleAPIClient = async function (gapi) {
  // 1. ensure client support is loaded first
  await new Promise((resolve, reject) => {
    // don't use 'auth2' module since GIS handles that part now
    gapi.load("client", { callback: resolve, onerror: reject })
  })
  // 2. initialize client - 'scope' and 'client_id' are now handled by GIS
  await gapi.client.init({})
  // 3. load API discovery documents
  await gapi.client.load("https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest")
  await gapi.client.load("https://www.googleapis.com/discovery/v1/apis/drive/v3/rest")
}

// inject the google API client library js into the page and initialize
const loadGAPI = function () {
  return new Promise((resolve) => {
    if (state.loadedGAPI) resolve(window.gapi)
    if (!state.loadingGAPI) {
      state.loadingGAPI = true
      const scriptEl = document.createElement("script")
      scriptEl.addEventListener("load", () => {
        initalizeGoogleAPIClient(window.gapi).then(() => {
          state.loadedGAPI = true
          state.loadingGAPI = false
          resolve(window.gapi)
        })
      })
      scriptEl.src = "https://apis.google.com/js/api.js"
      scriptEl.async = true
      scriptEl.defer = true
      document.head.appendChild(scriptEl)
    }
  })
}

// inject the google identity services js into the page and initialize
const loadGIS = function () {
  return new Promise((resolve) => {
    if (state.loadedGIS) resolve(window.google)
    if (!state.loadingGIS) {
      state.loadingGIS = true
      const scriptEl = document.createElement("script")
      scriptEl.addEventListener("load", () => {
        initializeSignInClient(window.google).then(() => {
          state.loadedGIS = true
          state.loadingGIS = false
          resolve(window.google)
        })
      })
      scriptEl.src = "https://accounts.google.com/gsi/client"
      scriptEl.async = true
      scriptEl.defer = true
      document.head.appendChild(scriptEl)
    }
  })
}

export const initGoogleScripts = async function () {
  const googleStore = useGoogleStore()
  // GAPI needs to be loaded first so GIS can detect it
  await loadGAPI()
  // we need current public key to decode JWT from GIS
  await fetchGooglePublicKey()
  // now we can load GIS
  await loadGIS()
  // notify store that we are done loading
  await googleStore.libraryLoadingComplete()
}

export const initGoogle = async function () {
  await initGoogleScripts()
  await fetchAccessToken()
  const googleStore = useGoogleStore()
  googleStore.setReady(true)
}
