<template>
  <div class="level toolbar is-size-5">
    <div class="level-left">
      <div class="level-item">
        <a class="button is-small has-text-weight-semibold" @click="shiftWeeks(-1)">
          ⇦ Prev Week
        </a>
      </div>
    </div>

    <div class="level-middle">
      <div class="tags filter-tags">
        <span v-for="f in filters" :key="f" :class="tagClass(f)" @click="filter = f">
          {{ f }}
        </span>
      </div>
    </div>

    <div class="level-right">
      <div class="level-item">
        <a class="button is-small" @click="loadCurrentWeek()">This week</a>
      </div>
      <div class="level-item">
        <a class="button is-small has-text-weight-semibold" @click="shiftWeeks(1)"> Next Week ⇨ </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    filterMode: {
      type: String,
      default: "triage",
      required: false,
    },
  },
  emits: ["set-filter", "shift-weeks", "load-current-week"],
  data() {
    return {
      filters: [
        "triage",
        "portfolio",
        "pipeline",
        "networking",
        "management",
        "personal",
        "other",
        "all",
      ],
    }
  },
  computed: {
    filter: {
      get() {
        return this.modelValue
      },
      set(filter) {
        this.$emit("set-filter", filter)
      },
    },
  },
  methods: {
    tagClass(filter) {
      return {
        tag: true,
        "is-link": filter === this.filterMode,
      }
    },
    shiftWeeks(weeks) {
      this.$emit("shift-weeks", weeks)
    },
    loadCurrentWeek() {
      this.$emit("load-current-week")
    },
  },
}
</script>

<style lang="sass">
#reporting-calendar > .toolbar
  margin-bottom: 1rem
  padding-bottom: 0.5rem
  border-bottom: 1px solid #e5e5e5
  .filter-tags span.tag
    cursor: pointer
</style>
