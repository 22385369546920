<template>
  <section id="fund-list" ref="fundList" class="section">
    <div>
      <h1 class="is-size-2">
        <router-link
          ref="allVehicles"
          :to="{ name: 'fund-show', params: { slug: 'all' } }"
          class="has-text-primary"
        >
          All Heavybit Vehicles
        </router-link>
      </h1>
      <p class="has-text-grey-light">All investment funds and vehicles</p>
    </div>

    <div v-for="fundType in fundsByType" :key="fundType">
      <div v-for="fund in fundType.funds" :key="fund.slug">
        <h2 class="is-size-3">
          <router-link :to="{ name: 'fund-show', params: { slug: fund.slug } }" class="fund-link">
            {{ fund.name }}
          </router-link>
        </h2>
        <p v-if="fund.description" class="has-text-grey-light">{{ fund.description }}</p>
        <p v-else class="has-text-grey-light is-italic">No description available</p>
      </div>
    </div>
  </section>
</template>

<script>
import { organizations } from "@/common/pipedrive/resources"

export default {
  data() {
    return {
      funds: [],
    }
  },
  computed: {
    fundsByType() {
      // Generate a list of all (actual) fund types from the segment field in pipedrive
      const funds = this.funds.filter((fund) => fund.slug !== "all")
      const fundTypes = [...new Set(funds.map((fund) => fund.segment))]

      // Group funds by type
      return fundTypes.map((fundType) => ({
        type: fundType,
        funds: this.funds.filter((fund) => fund.segment === fundType),
      }))
    },
  },
  created() {
    const localFunds = sessionStorage.getItem("funds")
    if (localFunds) this.funds = JSON.parse(localFunds)
  },
  mounted() {
    this.fetchFunds()
    this.$nextTick(() => this.$refs.allVehicles.$el.focus())

    if (!this.$mousetrap) return
    this.$mousetrap.bind("j", () => this.next())
    this.$mousetrap.bind("k", () => this.next(false))
    this.$mousetrap.bind("l", () => document.activeElement.click())
  },
  beforeUnmount() {
    if (!this.$mousetrap) return
    ;["j", "k"].forEach((k) => this.$mousetrap.unbind(k))
  },
  methods: {
    fetchFunds() {
      organizations
        .byFilter(227, { sort: "add_time ASC" })
        .then((result) => {
          this.funds = [
            // Prepend a default synthetic fund for all vehicles
            {
              id: 0,
              slug: "all",
              name: "All Heavybit Vehicles",
              description: "All investment funds and vehicles",
              segment: "All",
            },
            ...result.map((fund) => {
              // Append the funds description along with the URL field as a ref for the router & slug
              return {
                id: fund.id,
                name: fund.name,
                slug: fund["b25fb441467be944f6983024dd7673be1350aa2b"] || "",
                description: fund["426a3fe18ecb9344e548ee31614be1dd905d1920"] || "",
                segment: fund["528d1845ce1cadb0a6d25069b65836d3986d00c8"] || "",
              }
            }),
          ]
          sessionStorage.setItem("funds", JSON.stringify(this.funds))
        })
        .catch((err) => {
          this.$toasted.error(`Error fetching funds: ${err}`)
          console.log(err)
        })
    },
    next(forward = true) {
      const links = [...this.$refs.fundList.getElementsByTagName("a")]
      const index = Math.max(links.indexOf(document.activeElement), 0)
      links[(forward ? index + 1 : index + links.length - 1) % links.length].focus()
    },
  },
}
</script>

<style lang="sass">
#fund-list
  > div
    padding-bottom: 1.5rem
  > div:not(:first-child)
    padding-top: 1.5rem
    border-top: 1px solid #e5e5e5
  .fund-link
    color: #d97408
  a:focus
    outline: none
    &:before
      content: "\276F"
      color: #d97408
      position: absolute
      margin-left: -1.2rem
      margin-top: 0.4rem
      padding: 0
      font-size: 75%
      font-weight: 300
</style>
