import { bearerHeader } from "@/common/authorization"
import { fetchClient } from "@/common/fetchclient"

// returns client instance preconfigured for requests to our netlify functions
// example of use:
//   client = netlifyFunctionClient(opts);
//   const response = await client.get('foo'); // call 'foo' function
export const netlifyFunctionClient = function (options = {}) {
  const site = window.location.origin
  const defaults = {
    baseURL: site + "/.netlify/functions/",
    headers: {
      "X-Requested-With": "XmlHttpRequest", // CSRF prevention
      Authorization: bearerHeader(),
    },
  }
  const config = Object.assign({}, defaults, options)
  return fetchClient(config)
}
