<template>
  <div class="attendee-list">
    <p v-if="!attendees || !sortedAttendees.length" class="is-size-7 is-disabled is-italic">
      No attendees
    </p>
    <ul>
      <li v-for="person in sortedAttendees" :key="person.email" :class="statusClass(person)">
        <a v-if="isPipedriveContact(person)" :href="personUrl(person)" target="_blank">
          <img :src="avatarUrl(person)" class="avatar is-small" />
          <span>{{ personName(person) }}</span>
        </a>

        <template v-else>
          <img :src="avatarUrl(person)" class="avatar is-small" />
          <span :title="person.email">{{ personName(person) }} </span>
          <template v-if="hasColleague(person)">
            <a class="button is-small is-info is-light" @click="addContact(person)">
              Add to {{ suggestedOrg(person).name }}
            </a>
          </template>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import pipedrive from "@/common/pipedrive"

export default {
  props: {
    attendees: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  emits: ["add-contact"],
  computed: {
    sortedAttendees() {
      let excludedAttendees = ["calendar-notification@google.com"]
      let attendeeFilter = (p) => !p.resource && !excludedAttendees.includes(p.email)
      return [...this.attendees].filter(attendeeFilter).sort(this.sortPeople)
    },
    organizationsByDomain() {
      // Create a map of domains to an organization id & name
      let domains = this.attendees.reduce((acc, person) => {
        let domain = person.email.split("@")[1]
        if (!acc[domain]) {
          // skip duplicate orgs - just use the first one
          acc[domain] = { id: person.organizationId, name: person.organizationName }
        }
        return acc
      }, {})
      return domains
    },
  },
  methods: {
    avatarUrl(person) {
      return person.imageUrl || "/img/avatar_120x120.png"
    },
    isPipedriveContact(person) {
      return person.pipedriveId > 0
    },
    hasColleague(person) {
      return this.suggestedOrg(person).id > 0
    },
    suggestedOrg(person) {
      let domain = person.email.split("@")[1]
      return this.organizationsByDomain[domain]
    },
    personUrl(person) {
      return pipedrive.web.personUrl(person.pipedriveId)
    },
    personName(person) {
      return person.displayName || person.email
    },
    sortPeople(a, b) {
      // Sort by heavybit.com first then by name
      let aEmail = a.email.toLowerCase()
      let bEmail = b.email.toLowerCase()
      let aDomain = aEmail.split("@")[1] || ""
      let bDomain = bEmail.split("@")[1] || ""
      let aIsHB = aDomain === "heavybit.com"
      let bIsHB = bDomain === "heavybit.com"
      if (aIsHB && !bIsHB) return -1
      if (!aIsHB && bIsHB) return 1
      return aEmail.localeCompare(bEmail)
    },
    statusClass(p) {
      let status = p.organizer ? p.responseStatus + " organizer" : p.responseStatus
      return p.email?.indexOf("heavybit.com") > -1 ? status + " heavybit" : status
    },
    addContact(person) {
      let domain = person.email.split("@")[1]
      let org = this.organizationsByDomain[domain]
      // Error out if we can't find the org
      if (!org?.id) {
        this.$toasted.error("Could not find organization for " + person.email)
        console.log("Could not find organization from ", person, org, this.organizationsByDomain)
        return
      }
      // Emit a message creating a new Pipedrive contact
      let pipedrivePerson = {
        name: this.personName(person),
        email: person.email,
        org_id: org.id,
      }
      this.$emit("add-contact", pipedrivePerson)
    },
  },
}
</script>

<style lang="sass">
.attendee-list
  max-height: 13rem
  overflow: scroll
  ul li
    font-size: 0.75rem
    overflow: hidden
    white-space: nowrap
    position: relative
    img.avatar.is-small
      width:28px !important
      height:28px !important
      padding:0 !important
      margin-right: 0.3rem
      position: relative
      border: none !important
    span
      color: #363636
      display: inline-block
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      padding-bottom: 4px
      padding-right: 14px
    a.button
      height: 22px
      margin-top: 4px
      margin-right: auto
      position: absolute
      right: 20px
      border-radius: 4px !important
      box-shadow: white 0 0 4px 8px
  ul li:after
    display: inline
    position: absolute
    font-size: 14px
    top: 3px
    right: 0
    background: #ffff
  .organizer
    img.avatar
      opacity: 0.8
    span
      font-weight: 500
  .accepted:after
    content: '✓'
    font-weight: 700
    color: ForestGreen
    display: inline-block
    padding: 0
  .needsAction:after
    content: '?'
    font-weight: 700
    color: lightgray
    font-size: 15px
    top: 2px
    padding-right: 1px
  .declined:after
    content: '✗'
    font-weight: 700
    color: red
    padding-right: 1px
</style>
