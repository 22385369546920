<template>
  <section class="section overview">
    <router-link :to="{ name: 'program-overview' }" class="switch">Switch to Program</router-link>

    <div class="portfolio-overview">
      <h1 class="is-size-2">Portfolio Overview</h1>

      <table ref="memberTable" class="table is-fullwidth" :class="{ 'by-month': monthOrder }">
        <thead>
          <SortableTableHeader
            v-for="(column, index) in columns"
            :key="index"
            :field="column.field"
            :selected="order"
            @sort="sort"
          >
            {{ column.title }}
          </SortableTableHeader>
        </thead>
        <tbody>
          <OverviewRow v-for="(member, index) in orderedMembers" :key="index" :member="member" />
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import OverviewRow from "@/components/OverviewRow.vue"
import SortableTableHeader from "@/components/SortableTableHeader.vue"
import queries from "@/common/queries"
import { currentRunway, lastARR } from "@/utils/analysis"
import { lastMetricValue, lastMetricMeasurement } from "@/utils/measurements"
import { toDate, monthsAgo } from "@/utils/date"
import { differenceInCalendarMonths } from "date-fns"
import orderBy from "lodash/orderBy"

export default {
  components: {
    OverviewRow,
    SortableTableHeader,
  },
  data() {
    return {
      columns: [
        { field: "name", title: "Member" },
        { field: "arr", title: "Revenue (ARR)" },
        { field: "runway", title: "Runway (Mos)" },
        { field: "customers", title: "New Customers" },
        { field: "programMonth", title: "Program Month" },
        { field: "updated", title: "Last Update" },
      ],
      members: [],
      order: "programMonth",
      orderAscending: true,
    }
  },
  apollo: {
    members: {
      query: queries.membersForOverview,
      variables: { earliestMeasurementDate: monthsAgo(9) },
      update(data) {
        return this.addDerivedData(data.members)
      },
      fetchPolicy: "cache-and-network",
      error() {
        this.$toasted.error("Error loading member data.")
      },
    },
  },
  computed: {
    monthOrder() {
      return this.order === "programMonth"
    },
    orderedMembers() {
      const sortDirection = this.orderAscending ? "asc" : "desc"
      return orderBy(this.members, [this.order], [sortDirection])
    },
  },
  mounted() {
    if (!this.$mousetrap) return
    this.$mousetrap.bind("j", () => this.nextMember())
    this.$mousetrap.bind("k", () => this.nextMember(false))
  },
  beforeUnmount() {
    if (!this.$mousetrap) return
    ;["j", "k"].forEach((k) => this.$mousetrap.unbind(k))
  },
  methods: {
    addDerivedData(members) {
      const enhanced = []
      members.forEach((member) => {
        const fields = {
          arr: lastARR(member.measurements),
          customers: this._customersFor(member),
          programMonth: this._programMonth(member),
          runway: currentRunway(member.measurements),
          updated: this._lastUpdateFor(member),
        }
        enhanced.push(Object.assign({}, member, fields))
      })
      return enhanced
    },
    sort(orderBy, ascending) {
      this.order = orderBy
      this.orderAscending = ascending
    },
    _customersFor(member) {
      let metric = "conversions"
      if (member.go_to_market && member.go_to_market.name === "enterprise") {
        metric = "closed"
      }
      return lastMetricValue(member.measurements, metric)
    },
    _lastUpdateFor(member) {
      const updated = lastMetricMeasurement(member.measurements, "MRR")
      return updated ? updated.ends_on : null
    },
    _programMonth(member) {
      if (!member.entered_heavybit_on) return null
      return differenceInCalendarMonths(new Date(), toDate(member.entered_heavybit_on))
    },
    nextMember(forward = true) {
      const table = this.$refs.memberTable
      const member = document.activeElement
      if (!table.contains(member)) return table.getElementsByTagName("a")[0].focus()

      const members = [...table.getElementsByTagName("a")]
      const index = members.indexOf(document.activeElement)
      members[(index + members.length + (forward ? 1 : -1)) % members.length].focus()
    },
  },
}
</script>

<style lang="sass" scoped>
.section.overview
  .switch
    float: right
    font-size: 0.8rem
    color: lightgray
    margin-top: 1.2rem
    &:hover
      color: darkgray
table
  font-size: 1.3rem
th
  font-size: 1.1rem
  font-weight: 500
  color: #737373 !important
  text-align: right
  &:first-child
    text-align: left
  &.fixed
    width: 16%
    padding-left: 0
table :deep(a:focus)
    color: #d97408 !important
    outline: none
    &:before
      content: "\276F"
      color: #d97408
      position: absolute
      margin-left: -0.8rem
      margin-top: 0.2rem
      padding: 0
      font-size: 1.1rem
tbody
  font-weight: 300
table.by-month
  .stage-active + .stage-graduate
    border-top: 2px solid hsla(0,0%,80%,1)
</style>
