import { defineStore } from "pinia"

export const useUserStore = defineStore("user", {
  state: () => ({
    authenticated: false,
    loggingOut: false,
    profile: {},
    user: {
      email: null,
      name: null,
      fireflies_token: null,
      pipedrive_token: null,
      partner: false,
    },
    userId: null,
  }),

  getters: {
    hadAuthenticatedSession: (state) => state.authenticated || state.loggingOut,
    email: (state) => state.user?.email,
    name: (state) => state.user?.name,
    isPartner: (state) => state.user?.partner,
    // tokens & their availability
    firefliesReady: (state) => !!state.user?.fireflies_token,
    firefliesToken: (state) => state.user?.fireflies_token,
    pipedriveReady: (state) => !!state.user?.pipedrive_token,
    pipedriveToken: (state) => state.user?.pipedrive_token,
  },

  actions: {
    updateAuthInfo({ loggedIn, profile }) {
      this.authenticated = loggedIn
      this.profile = profile

      // this is our user id for our primary DB. however we receive it from auth0
      // in a nested data structure of JWT claims, so dig it out for easier access.
      const userId =
        profile &&
        profile["https://hasura.io/jwt/claims"] &&
        profile["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
      this.userId = userId || null // unset if logged out
    },

    updateProperties(payload) {
      this.$patch({ user: payload })
    },

    setForcedUser(id) {
      this.authenticated = true
      this.userId = id
    },

    startLogout() {
      this.loggingOut = true
      // clear sensitive user properties
      this.user.fireflies_token = null
      this.user.pipedrive_token = null
    },
  },
})
