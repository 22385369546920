<template>
  <section id="member-list" class="section">
    <div ref="stages" class="columns">
      <div v-for="stage in stages" :key="stage.id" class="column">
        <h2 class="is-size-3">
          {{ $filters.capitalize(stage.name) }}
          <span class="is-size-4 has-text-grey-light">
            {{ stage.members.length }}
          </span>
        </h2>
        <ul>
          <li
            v-for="member in membersFor(stage)"
            :key="member.id"
            class="member-entry py-1"
            :class="{ 'is-fundraising': member.fundraising }"
          >
            <router-link
              :to="{ name: 'member-show', params: { id: member.id } }"
              class="has-text-primary has-text-weight-light"
            >
              {{ member.name }}
            </router-link>
          </li>
          <template v-if="stage.members.length > limit">
            <li v-if="isExpanded(stage)">
              <a class="is-size-7 has-text-grey-light" @click="toggleStage(stage)">Show less ⇧</a>
            </li>
            <li v-else>
              <a class="is-size-7 has-text-grey-light" @click="toggleStage(stage)">Show more ⇩</a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import queries from "@/common/queries"

export default {
  apollo: {
    stages: {
      query: queries.membersByStage,
      fetchPolicy: "cache-and-network",
    },
  },
  data() {
    return {
      stages: [
        { id: 1, name: "active", members: [] },
        { id: 2, name: "graduate", members: [] },
        { id: 3, name: "alumni", members: [] },
        { id: 4, name: "exited", members: [] },
      ],
      limit: 12,
      expanded: {
        active: false,
        graduate: false,
        alumni: false,
        exited: false,
      },
    }
  },
  mounted() {
    if (!this.$mousetrap) return
    this.$mousetrap.bind("j", this.nextMember)
    this.$mousetrap.bind("k", () => this.nextMember(false))
    this.$mousetrap.bind("h", () => this.nextStage(false))
    this.$mousetrap.bind("l", this.nextStage)
  },
  beforeUnmount() {
    if (!this.$mousetrap) return
    ;["j", "k", "h", "l"].forEach((k) => this.$mousetrap.unbind(k))
  },
  methods: {
    isExpanded(stage) {
      return this.expanded[stage.name]
    },
    toggleStage(stage) {
      this.expanded[stage.name] = !this.expanded[stage.name]
    },
    membersFor(stage) {
      let members = this.stages.find((s) => s.id === stage.id).members || []
      return this.isExpanded(stage) ? members : members.slice(0, this.limit)
    },
    nextMember(forward = true) {
      const member = document.activeElement
      const stages = this.$refs.stages
      if (!stages.contains(member)) return stages.getElementsByTagName("a")[0].focus()

      const members = [...stages.getElementsByTagName("a")]
      const index = members.indexOf(document.activeElement)
      members[(index + members.length + (forward ? 1 : -1)) % members.length].focus()
    },
    nextStage(forward = true) {
      const member = document.activeElement
      const stages = this.$refs.stages
      if (!stages.contains(member)) return stages.getElementsByTagName("a")[0].focus()

      const columns = [...stages.getElementsByClassName("column")]
      const colIndex = Math.max(
        columns.findIndex((stage) => stage.contains(member)),
        0
      )
      const nextStage = columns[(colIndex + columns.length + (forward ? 1 : -1)) % columns.length]
      const oldMembers = [...columns[colIndex].getElementsByTagName("a")]
      const newMembers = [...nextStage.getElementsByTagName("a")]
      const memberIndex = Math.max(oldMembers.indexOf(member), 0)
      newMembers[Math.min(memberIndex, newMembers.length - 1)].focus()
    },
  },
}
</script>

<style lang="sass">
.member-entry
  max-width: 200px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  color: rgb(247, 141, 28)
  font-size: 1.7rem
  letter-spacing:-1px
  a:focus
    color: $orange-light !important
    outline: none
    &:before
      content: "\276F"
      color: $orange-light
      position: absolute
      margin-left: -1.0rem
      margin-top: 0.25rem
      padding: 0
      font-size: 1.4rem
.member-entry.is-fundraising
  a:after
    content: '$'
    color: $steel-gray-dark
    font-size: 1rem
    font-weight: normal
    position: relative
    top: -1.0rem
</style>
