import { fetchAccessToken } from "./token"
import { ensureGoogleReady } from "./util"
import { useGoogleStore } from "@/stores/google"

const EXPIRY_BUFFER = 5 * 60 * 100 // 5m in milliseconds

const activeToken = function (token) {
  if (!token?.token) return false
  const expires = new Date(token.expires_at).getTime()
  const now = new Date().getTime()
  if (expires > now + EXPIRY_BUFFER) return true
}

// attempt token refresh if it makes sense
const refreshToken = async function () {
  const googleStore = useGoogleStore()
  // don't attempt if the user needs to manually authorize
  if (googleStore.authorizationNeeded) return false
  await fetchAccessToken()
  return activeToken(googleStore.token)
}

// returns a client instance with an active access token (if possible)
const getAuthorizedClient = async function () {
  // ensure everything is fully loaded
  await ensureGoogleReady()

  const googleStore = useGoogleStore()
  if (activeToken(googleStore.token)) return window.gapi.client

  const gotNewToken = await refreshToken()
  if (gotNewToken) return window.gapi.client

  throw new Error("No valid token available")
}

// returns an active client instance prepared to run API calls for
// service. if client cannot be authorized returns undefined;
export const googleClient = async function () {
  let client = undefined
  try {
    client = await getAuthorizedClient()
  } catch (err) {
    console.log("Google client is not authorized - skipping")
  }
  return client
}
