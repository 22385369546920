<template>
  <div class="event-title">
    <h3 class="is-size-5">
      <a :href="event.htmlLink" target="_blank">{{ event.summary }}</a>
    </h3>
    <h4 class="event-location is-size-6">
      <span v-if="!hasLocations" class="is-size-6 is-disabled">No location set</span>
      <span v-for="(location, index) in locations" :key="index">
        <a v-if="isLink(location)" :href="location" class="subtle-link" target="_blank">
          {{ truncate(location, 60) }}
        </a>
        <template v-else>{{ truncate(location, 80) }}</template>
        <template v-if="index != locations.length - 1">, </template>
      </span>
    </h4>
  </div>
</template>

<script>
import { truncate } from "@/utils/stringFormatter"

export default {
  components: {},
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasLocations() {
      return this.event?.location?.length > 0
    },
    locations() {
      if (!this.hasLocations) return []
      return this.event.location.split(", ").filter((location) => location.length > 0)
    },
  },
  methods: {
    truncate,
    isLink(location) {
      return location.startsWith("http")
    },
  },
}
</script>

<style lang="sass">
.event-title a
  color: #4a4a4a
.modal-container
  .event-title a
    font-weight: 600
</style>
