import { useGoogleStore } from "@/stores/google"
import { netlifyFunctionClient } from "@/common/functions"
import { getUserEmail } from "./util"

export const setAccessToken = function (tokenInfo) {
  const googleStore = useGoogleStore()
  googleStore.setToken(tokenInfo)
  googleStore.setAuthorizationNeeded(false)
  window.gapi.client.setToken({ access_token: tokenInfo.token })
}

// fetch current access token from backend. if token is unavailable
// backend may tell us that authorization is needed.
export const fetchAccessToken = async function () {
  await getUserEmail() // ensure user is fully loaded
  return netlifyFunctionClient()
    .get("google_token")
    .then((response) => {
      const token = response.data?.token
      const status = response.data?.status
      if (token) {
        setAccessToken(token)
      } else if (status == "authorization_needed") {
        const googleStore = useGoogleStore()
        googleStore.setAuthorizationNeeded(true)
      } else {
        console.error("Error requesting google token", response)
      }
    })
    .catch((err) => console.error(err))
}
