<template>
  <div class="card">
    <header class="card-header">
      <h3 class="card-header-title">{{ title }}</h3>
      <span v-if="trailingAverage" class="t4 card-header-icon" :class="trailingColor">
        <font-awesome-icon v-if="trailingPositive" icon="caret-up" />
        <font-awesome-icon v-if="trailingNegative" icon="caret-down" />
        <span class="percentage">{{ trailingAverage.toFixed(1) }}%</span>
      </span>
    </header>
    <div class="bignum">
      <span>{{ num }}</span>
      <div class="subtitle">{{ units }}</div>
    </div>
  </div>
</template>

<script>
import { isPositive, isNegative } from "@/utils/number"
import { percentageThreshold } from "@/utils/analysis"

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    num: {
      type: [Number, String],
      required: true,
    },
    units: {
      type: String,
      default: "",
    },
    trailingAverage: {
      type: Number,
      default: null,
    },
  },
  computed: {
    trailingPositive() {
      return isPositive(this.trailingAverage)
    },
    trailingNegative() {
      return isNegative(this.trailingAverage)
    },
    trailingColor() {
      return "is-" + percentageThreshold(this.trailingAverage)
    },
  },
}
</script>

<style lang="sass" scoped>
.bignum
  font-size: 3.25rem
  font-weight: 200
  text-align: center
  padding-left: 1.35rem
  padding-right: 1.35rem
  // account for spacing above type itself
  position: relative
  top: -10px
  .subtitle
    margin: -1rem
.card
  margin-bottom: 30px
.card-header
  text-align: center
.t4
  font-size: 0.9rem
  .percentage
    padding-left: 2px
</style>
