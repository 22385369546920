import { watch } from "vue"
import { hasuraClient } from "@/init/apollo"
import { general as config } from "@/common/config"
import auth from "@/services/authService"
import Sentry from "@/init/sentry"
import queries from "@/common/queries"
import { useUserStore } from "@/stores/user"
import pick from "lodash/pick"

const UserStateService = (function () {
  auth.addListener("authenticate", updateAuthentication)

  // unmounted() {
  //   auth.removeListener("authenticate", this.onAuthenticate);
  // },

  function fetchUserProperties() {
    const userStore = useUserStore()
    return hasuraClient
      .query({
        query: queries.fetchUser,
        variables: { id: userStore.userId },
      })
      .then((queryResult) => {
        const fields = ["email", "name", "fireflies_token", "pipedrive_token", "partner"]
        if (queryResult.data?.user) {
          const userData = pick(queryResult.data.user, fields)
          userStore.updateProperties(userData)
          setSentryUserContext(userData)
        }
      })
      .catch((error) => console.error("user property fetching error", error))
  }

  function replicateStateForUser(userId) {
    const userStore = useUserStore()
    userStore.setForcedUser(userId)
    return fetchUserProperties()
  }

  function setSentryUserContext(userData) {
    const userStore = useUserStore()
    const context = {
      id: userStore.userId,
      email: userData.email,
      username: userData.name,
    }
    Sentry.setUser(context)
  }

  async function updateAuthentication(event) {
    const userStore = useUserStore()
    await userStore.updateAuthInfo(event.detail)
    if (event.detail.loggedIn) {
      await fetchUserProperties()
    }
  }

  // expose public interface
  return {
    identify() {
      if (auth.enabled) {
        auth.renewTokens().catch((err) => console.log(err))
      } else if (config.forcedUser) {
        replicateStateForUser(config.forcedUser)
      }
    },

    // returns a promise which won't resolve until user is logged in & profile is loaded
    waitForLogin() {
      return new Promise((resolve) => {
        const userStore = useUserStore()
        if (userStore.email) return resolve()
        // resolve when the store tells us we are ready
        watch(
          () => userStore.email,
          (newVal) => {
            if (newVal) resolve()
          }
        )
      })
    },
  }
})()

export default UserStateService
