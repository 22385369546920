<template>
  <div class="level stats-bar">
    <div class="level-right">
      <div v-for="(stat, index) in stats" :key="index" class="level-item has-text-centered">
        <div>
          <p class="heading" v-html="stat.title"></p>
          <p class="title has-text">{{ stat.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>

<style lang="sass">
.stats-bar
  .level-item
    margin-right: 1.25rem !important
    .heading
      color: #7a7a7a
    .title
      font-weight: 200
</style>
