<template>
  <div class="event-properties">
    <strong>Private:</strong>
    <ul v-if="properties && properties.private">
      <li v-for="(val, key) in properties.private" :key="key">
        <strong>{{ key }}: </strong>
        <span>{{ val }}</span>
      </li>
    </ul>
    <span v-else class="is-disabled is-italic"> N/A</span>

    <ul>
      <li>
        <div class="field has-addons">
          <div class="control" style="width: 30%">
            <input
              v-model="newProperties.private.key"
              class="input is-small"
              type="text"
              placeholder="Key:"
            />
          </div>

          <div class="control" style="width: 70%">
            <input
              v-model="newProperties.private.value"
              class="input is-small"
              type="text"
              placeholder="Value"
              @keyup.enter="saveProperty('private')"
            />
          </div>
        </div>
      </li>
    </ul>
    <br />

    <strong>Shared:</strong>
    <ul v-if="properties && properties.shared">
      <li v-for="(val, key) in properties.shared" :key="key">
        <strong>{{ key }}: </strong>
        <span>{{ val }}</span>
      </li>
    </ul>
    <span v-else class="is-disabled is-italic"> N/A</span>

    <ul>
      <li>
        <div class="field has-addons">
          <div class="control" style="width: 30%">
            <input
              v-model="newProperties.shared.key"
              class="input is-small"
              type="text"
              placeholder="Key"
            />
          </div>

          <div class="control" style="width: 70%">
            <input
              v-model="newProperties.shared.value"
              class="input is-small"
              type="text"
              placeholder="Value"
              @keyup.enter="saveProperty('shared')"
            />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    properties: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ["save"],
  data() {
    return {
      newProperties: {
        private: { key: null, value: null },
        shared: { key: null, value: null },
      },
    }
  },
  methods: {
    saveProperty(type) {
      const property = type == "private" ? this.newProperties.private : this.newProperties.shared
      const extendedProperties = {
        [type]: {
          isSet: true,
          [property.key]: property.value,
        },
      }

      if (property.key) this.$emit("save", extendedProperties)
    },
  },
}
</script>

<style lang="sass" scoped>
.event-properties
  font-size: 0.75rem
  ul li
    margin-left: 0.5rem
    display: flex
    span
      margin-left: 0.3rem
      max-width: 12rem
      overflow: auto
      white-space: nowrap
  input.is-small
    font-size: 0.65rem
    padding-left: 0
    border: none
    box-shadow: none
    border-radius: 0
    border-bottom: 1px solid lightgray
  .control:first-child input
    font-weight: 700
</style>
