<template>
  <section class="section">
    <h2 class="is-size-3 is-inline-block">Metrics</h2>
    <MetricAdd class="is-pulled-right" :member-id="member.id" :exclusions="metrics" />
    <div
      ref="wrapper"
      class="metrics-table-wrapper is-clearfix"
      @mouseenter="disableOverscroll"
      @mouseleave="enableOverscroll"
      @scroll="handleScroll"
    >
      <table id="metrics-table" v-click-outside="stopEditing" class="table is-hoverable">
        <thead>
          <tr>
            <th>Period</th>
            <th v-for="(period, index) in periods" :key="index">{{ shortMonthYear(period) }}</th>
          </tr>
        </thead>
        <tbody v-for="(metricSet, index) in metricSets" :key="index">
          <tr v-for="metric in metricSet" :key="metric.id">
            <th>{{ $filters.capitalize(metric.name) }}</th>
            <MetricTableMeasurement
              v-for="(measurement, mIndex) in measurementsFor(metric)"
              :key="mIndex"
              :virtual-id="metric.name + '-' + mIndex"
              :measurement="measurement"
              :metric="metric"
              :member-id="member.id"
              :editing-id="editingId"
              @edit-cell="updateEditing"
              @move-measurement="moveToMeasurement"
              @contextmenu.prevent.stop="showCellMenu($event, measurement)"
              @mouseover="displayTooltip($event, measurement)"
              @mouseleave="clearTooltip(measurement)"
            />
          </tr>
        </tbody>
      </table>
    </div>
    <MeasurementTooltip v-show="tooltipText" :text="tooltipText" :position="tooltipCoordinates" />
    <MeasurementAnnotationForm
      v-show="annotatingMeasurement"
      :measurement="annotatingMeasurement"
      :member-id="member.id"
      @hide-form="clearAnnotatingMeasurement"
    />
    <context-menu
      ref="contextMenu"
      element-id="metricContextMenu"
      :options="contextMenuOptions"
      @option-clicked="optionClicked"
    />
  </section>
</template>

<script>
import { mapState } from "pinia"
import MeasurementAnnotationForm from "@/components/MeasurementAnnotationForm.vue"
import MeasurementTooltip from "@/components/MeasurementTooltip.vue"
import MetricAdd from "@/components/MetricAdd.vue"
import MetricTableMeasurement from "@/components/MetricTableMeasurement.vue"

import metricSet from "@/common/metricSet"
import { useMemberStore } from "@/stores/member"
import { toDateString, isSameDay } from "@/utils/date"
import { format as dateFormat } from "@/utils/dateFormatter"
import ContextMenu from "vue-simple-context-menu"

export default {
  components: {
    ContextMenu,
    MeasurementAnnotationForm,
    MeasurementTooltip,
    MetricAdd,
    MetricTableMeasurement,
  },
  props: {
    metrics: {
      type: Array,
      required: true,
    },
    measurements: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      annotatingMeasurement: null,
      contextMenuMeasurement: null,
      clearingTooltip: false,
      contextMenuOptions: [{ name: "Annotate", slug: "annotate" }],
      editingId: null,
      hasPainted: false,
      lastHoveredId: null,
      tooltipCoordinates: {},
      tooltipText: null,
    }
  },
  computed: {
    ...mapState(useMemberStore, ["member", "periods"]),
    metricSets() {
      const sets = metricSet.segmentAndOrder(this.metrics)
      return [sets.operational, sets.financial]
    },
  },
  watch: {
    member(newVal, oldVal) {
      if (newVal.founded_on != oldVal.founded_on) {
        this.snapToLatestMeasurements()
      }
    },
    visible(newVal) {
      if (!this.hasPainted && newVal) {
        this.hasPainted = true
        this.snapToLatestMeasurements()
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll)
  },
  unmounted() {
    document.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    _metricAfter(name) {
      let nextIndex
      const index = this.metrics.findIndex((m) => m.name === name)
      if (index + 1 !== this.metrics.length) {
        nextIndex = index + 1
      } else {
        nextIndex = 0 // roll over
      }
      return this.metrics[nextIndex]
    },
    clearAnnotatingMeasurement() {
      this.annotatingMeasurement = null
    },
    clearTooltip(measurement) {
      if (measurement.id === this.lastHoveredId) this._resetTooltip()
    },
    enableOverscroll() {
      setTimeout(() => {
        document.querySelector("html").style.overscrollBehaviorX = null
      }, 100)
    },
    disableOverscroll($event) {
      if ($event.target != this.$refs.wrapper) return
      document.querySelector("html").style.overscrollBehaviorX = "contain"
    },
    displayTooltip($event, measurement) {
      if (measurement.measurement_annotation) {
        // ensure we are basing position on the td itself, not a child element
        const target = $event.target.closest("td")
        const clientRect = target.getBoundingClientRect()
        this.tooltipCoordinates = {
          top: window.scrollY + clientRect.top + 1,
          left: window.scrollX + (clientRect.left + clientRect.right) / 2,
        }
        this.tooltipText = measurement.measurement_annotation.annotation
        this.lastHoveredId = measurement.id
      } else {
        this._resetTooltip()
      }
    },
    handleScroll() {
      if (this.tooltipText && !this.clearingTooltip) {
        this.clearingTooltip = true
        window.requestAnimationFrame(this._resetTooltip)
      }
    },
    measurementsFor(metric) {
      let metricMeasures = this.measurements.filter((m) => m.metric == metric.name)
      let measures = []
      this.periods.forEach((period) => {
        let measure = metricMeasures.find((m) => isSameDay(m.ends_on, period))
        measures.push(measure || this.nullMeasurement(metric, period))
      })
      return measures
    },
    moveToMeasurement(event) {
      const [metric, position] = event.from.split("-")
      if (event.direction === "right") {
        const newPosition = Number(position) + 1
        this.updateEditing(metric + "-" + newPosition)
      } else if (event.direction === "left") {
        const newPosition = Number(position) - 1
        this.updateEditing(metric + "-" + newPosition)
      } else if (event.direction === "down") {
        const nextMetric = this._metricAfter(metric).name
        this.updateEditing(nextMetric + "-" + position)
      }
    },
    nullMeasurement(metric, period) {
      return {
        starts_on: toDateString(this.periodStart(period)),
        ends_on: toDateString(period),
        metric: metric.name,
        value: null,
      }
    },
    optionClicked({ option, _item }) {
      if (option.slug == "annotate") this.showAnnotationForm()
    },
    periodStart(period) {
      return new Date(period.getFullYear(), period.getMonth(), 1)
    },
    shortMonthYear(dateOrDateString) {
      return dateFormat(dateOrDateString, "MMM yy")
    },
    snapToLatestMeasurements() {
      // may need to calculate exact position, but try brute force first
      this.$nextTick(() => (this.$refs.wrapper.scrollLeft = 10000000))
    },
    showAnnotationForm() {
      this.annotatingMeasurement = this.contextMenuMeasurement
      this.contextMenuMeasurement = null
    },
    showCellMenu($event, measurement) {
      this.contextMenuMeasurement = measurement
      this.$refs.contextMenu.showMenu($event, measurement)
    },
    stopEditing() {
      this.editingId = null
    },
    updateEditing(id) {
      this.editingId = id
    },
    _resetTooltip() {
      this.tooltipText = null
      this.lastHoveredId = null
      this.clearingTooltip = false
    },
  },
}
</script>

<style lang="sass" scoped>
.metrics-table-wrapper
  overflow-x: scroll
  padding: 0.5em 0em 1em 0em
  scroll-snap-type: x mandatory
#metrics-table
  font-size: 0.9rem
tbody:not(:last-child)
  border-bottom: 2px solid #dbdbdb
tr:not(:last-child)
  border-bottom: 1px solid #dbdbdb
th
  scroll-snap-align: end
th, td
  min-width: 6.5em
  padding: 0.5em
  text-align: right
tr th:first-child
  text-align: left
  position: sticky
  width: 8em
  left: 0
  margin-top: -1px // fix alignment
  background: white
</style>

<style>
@import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
</style>
