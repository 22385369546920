<template>
  <div class="note media">
    <figure class="media-left">
      <img :src="avatarUrl" class="avatar" :alt="userName" :title="userName" />
    </figure>
    <div class="media-content">
      <div class="header">
        <div class="title has-text-weight-bold">
          {{ subject }}
        </div>
      </div>
      <p></p>
      <div class="content">
        <div v-show="!editing" class="display">
          <div class="note-content" v-html="noteContent"></div>
          <div class="toggle-edit">
            <span class="edit">
              <a @click="toggleEdit">Edit note</a>
            </span>
            <span v-if="isActivity" class="activity-link">
              <a target="_blank" :href="activityUrl">View activity</a>
            </span>
          </div>
        </div>
        <div v-if="editing" class="editing">
          <NoteEditor v-model="noteContent" />
          <div class="controls">
            <a class="button" @click="saveEdit">Update Note</a>
            <a class="button is-text" @click="cancelEdit">Cancel</a>
          </div>
        </div>
      </div>
    </div>
    <div class="media-right has-text-right">
      <h4>{{ addTimeInWords }}</h4>
      <h4 class="has-text-grey-light">{{ addTime.substring(0, 10) }}</h4>
    </div>
  </div>
</template>

<script>
import NoteEditor from "@/components/NoteEditor.vue"
import { activities, notes } from "@/common/pipedrive/resources"
import { sanitizeHTML } from "@/common/pipedrive/util"
import { activityUrl } from "@/common/pipedrive/web"
import { timeAgoInWords } from "@/utils/dateFormatter"

export default {
  components: { NoteEditor },
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  emits: ["update"],
  data() {
    return {
      editing: false,
      noteContent: "",
    }
  },
  computed: {
    addTime() {
      return this.note?.add_time
    },
    addTimeInWords() {
      return timeAgoInWords(this.addTime)
    },
    activityUrl() {
      if (!this.isActivity) return ""
      return activityUrl(this.note.id)
    },
    avatarUrl() {
      return this.note?.user?.icon_url || "/img/avatar_120x120.png"
    },
    isActivity() {
      const noteType = this.note?.type
      return noteType && noteType != "additional_notes"
    },
    subject() {
      return this.note?.subject || ""
    },
    userName() {
      return this.note?.user?.name || ""
    },
  },
  watch: {
    note(newNote) {
      this._initNoteContent(newNote)
    },
  },
  mounted() {
    this._initNoteContent()
  },
  methods: {
    cancelEdit() {
      this._initNoteContent()
      this.editing = false
    },
    saveEdit() {
      // notes displayed are a blend of two pipedrive resources, notes
      // and activities. Figure out which one we are updating so we
      // can hit the right endpoint.
      if (this.note.type == "additional_notes") {
        notes
          .update(this.note.id, { content: this.noteContent })
          .then((response) => {
            this.$emit("update", "note", response)
            this.$toasted.success("Note updated successfully.")
          })
          .catch(() => {
            this.$toasted.error("Failed updating note.")
          })
      } else {
        activities
          .update(this.note.id, { note: this.noteContent })
          .then((response) => {
            this.$emit("update", "activity", response)
            this.$toasted.success("Activity note updated successfully.")
          })
          .catch(() => {
            this.$toasted.error("Failed updating activity note.")
          })
      }
      this.editing = false
    },
    toggleEdit() {
      this.editing = !this.editing
    },
    _initNoteContent(note = this.note) {
      this.noteContent = sanitizeHTML(note?.content || "", true, 40)
    },
  },
}
</script>

<style lang="sass" scoped>
.header
  margin-bottom: 0.25rem

.toggle-edit
  margin-top: 0.5rem
  opacity: 0
  transition: opacity 0.2s ease-out
  .edit
    margin-right: 6px
  .activity-link
    padding: 0 6px
    border-left: 1px solid #ccc

.note:hover .toggle-edit
  height: auto
  opacity: 1

.editing
  .controls
    margin-top: 0.25rem
</style>
