<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click.self="clickOutside">
      <div v-if="!hasControls" class="modal-prev">
        <button class="button is-pulled-left is-light is-rounded" @click="$emit('prev')">
          <span class="icon"><font-awesome-icon icon="arrow-left" size="lg" /></span>
        </button>
      </div>
      <div class="modal-container">
        <div v-if="hasControls" class="modal-controls"></div>
        <div class="modal-content">
          <slot></slot>
        </div>
        <div v-if="hasControls" class="modal-controls">
          <slot name="controls"></slot>
        </div>
      </div>

      <div v-if="!hasControls" class="modal-next">
        <button class="button is-pulled-right is-light is-rounded" @click="$emit('next')">
          <span class="icon"><font-awesome-icon icon="arrow-right" size="lg" /></span>
        </button>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    clickOutsideToClose: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["prev", "close", "next"],
  computed: {
    hasControls() {
      return !!this.$slots.controls
    },
  },
  methods: {
    clickOutside() {
      if (this.clickOutsideToClose) {
        this.$emit("close")
      }
    },
  },
}
</script>

<style lang="sass">
.modal-mask
  position: fixed
  z-index: 9998
  top: 0
  left: 0
  width: 100%
  height: 100%
  // Make the background slightly transparent based on --bulma-dark
  background-color: color-mix(in srgb, var(--bulma-dark) 90%, transparent)
  display: flex
  align-items: center
  justify-content: center
  transition: all 0.2s ease

.modal-prev,
.modal-next
  padding: 2rem
  button
    width: 3.5rem
    height: 3.5rem

.modal-controls
  margin: 0 1.5rem
  width: 3rem
  align-self: flex-start
  overflow: hidden
  display: flex
  flex-direction: column
  gap: 0.8rem
  > *
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33)

.modal-container
  max-width: calc( 100% - 4rem)
  // max-height: calc( 100% - 4rem )
  transition: all 0.2s ease
  overflow: hidden
  display: flex
  .modal-content
    background: white
    // overflow: auto
    max-height: 90vh
    overflow: scroll
    max-width: calc( 100% - 12rem)
    padding: 1.4rem 1.5rem
    border-radius: 1rem
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33)
    flex-grow: 1
    z-index: 9999

// For mobile devices when the modal is shown, take up almost the entire screen
// Push the controls to the bottom and make them horizontal
@media screen and (max-width: 768px)
  .modal-container
    flex-direction: column
    height: 95%
    width: 95%
    // Hide the first modal-controls
    > .modal-controls:first-child
      display: none
    .modal-controls
      margin: 0
      padding-top: 1rem
      height: 4rem
      width: 100%
      flex-direction: row
      button.button
        min-width: 3rem
    .modal-content
      overflow: auto
      margin: 0

/* The following styles are auto-applied to elements with transition="modal" when
 * their visibility is toggled by Vue.js. */

.modal-enter-from
  opacity: 0

.modal-leave-to
  opacity: 0

.modal-enter-from .modal-container,
.modal-leave-to .modal-container
  transform: scale(0.8)
</style>
