const BASE_URL = "https://heavybit.pipedrive.com/"

export const activityUrl = (id) => {
  return BASE_URL + "activities/list?activity=" + id + "#dialog/activity/edit"
}

export const dealUrl = (id) => {
  return BASE_URL + "deal/" + id
}

export const organizationUrl = (id) => {
  return BASE_URL + "organization/" + id
}

export const personUrl = (id) => {
  return BASE_URL + "person/" + id
}

export default {
  activityUrl,
  dealUrl,
  organizationUrl,
  personUrl,
}
