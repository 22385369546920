<template>
  <div class="company-show">
    <section class="section">
      <header class="is-clearfix">
        <div class="is-pulled-left">
          <h1 class="is-size-1">{{ company.name }}</h1>
        </div>
        <div class="details is-pulled-right">
          <div id="company-tags" class="tags are-medium">
            <!-- tags -->
          </div>
          <router-link
            v-if="member"
            class="button"
            :to="{ name: 'member-show', params: { id: member.id } }"
          >
            <span>Go to Member</span>
            <span class="icon"><font-awesome-icon icon="arrow-circle-right" /></span>
          </router-link>
          <a v-else class="button" @click="addMember = true">
            <span class="icon"><font-awesome-icon icon="user-plus" /></span>
            <span>Add to Members</span>
          </a>
          <PipedriveButton :href="organizationUrl" />
        </div>
      </header>
      <p>{{ description }}</p>
      <p class="address">{{ company.address }}</p>
    </section>
    <TeamMembers :id="id" />
    <CompanyNotes :id="id" />
    <MemberAdd
      :id="id"
      :active="addMember"
      :add-props="addMemberProps"
      @close-add="addMember = false"
    />
  </div>
</template>

<script>
import CompanyNotes from "@/components/CompanyNotes.vue"
import MemberAdd from "@/components/MemberAdd.vue"
import TeamMembers from "@/components/TeamMembers.vue"
import PipedriveButton from "@/components/PipedriveButton.vue"
import { organizations } from "@/common/pipedrive/resources"
import pipedrive from "@/common/pipedrive"
import queries from "@/common/queries"

export default {
  components: {
    CompanyNotes,
    MemberAdd,
    PipedriveButton,
    TeamMembers,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      company: {},
      addMember: false,
    }
  },
  apollo: {
    members: {
      query: queries.fetchMembersByPipedriveId,
      variables() {
        return {
          id: this.id,
        }
      },
    },
  },
  computed: {
    description() {
      return this.company["426a3fe18ecb9344e548ee31614be1dd905d1920"]
    },
    member() {
      return this.members && this.members[0]
    },
    url() {
      return this.company["b25fb441467be944f6983024dd7673be1350aa2b"]
    },
    organizationUrl() {
      return pipedrive.web.organizationUrl(this.id)
    },
    addMemberProps() {
      return {
        name: this.company.name,
        pipedrive_id: this.id,
        member_stage_id: 1,
        company_stage_id: 2,
        go_to_market_id: null,
      }
    },
  },
  mounted() {
    this.fetchCompany()
  },
  methods: {
    fetchCompany() {
      organizations
        .byId(this.id)
        .then((response) => (this.company = response))
        .catch(() => this.$toasted.error("Unable to find requested company"))
    },
  },
}
</script>

<style lang="sass" scoped>
h1
  font-weight: 200
  display: inline-block
.company-show
  min-height: 650px
.address
  font-style: italic
.button
  margin-left: 5px
</style>
